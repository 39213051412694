import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Card, CardContent, Divider } from "@material-ui/core";
import { withTranslation } from 'react-i18next';
import Typography from '../../components/CustomComponents/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '../../components/CustomComponents/RadioGroup'
import Radio from '@material-ui/core/Radio';
import Button from '../../components/CustomComponents/Button';
import { withStyles } from "@material-ui/core";
import { samlStyle } from '../../components/CustomStyles/PublicPageStyles.jsx';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import WithUser from "../../ContextAndHOC/WithUser";
import queryString from 'query-string';

const styles = theme => samlStyle(theme);

const productList = [{ productId: 1, productName: "MyHOPE™", logo: "" }, { productId: 2, productName: "Connect® MM", logo: "/config_logo/Connect_MM_logo.png" }, { productId: 3, productName: "Prodigy™", logo: "/config_logo/ProdigyLogo.png" }]
const userRoles = [{ userRoleId: "1", userRole: "Super admin" }, { userRoleId: "2", userRole: "Admin" }, { userRoleId: "3", userRole: "Physician" }, { userRoleId: "4", userRole: "Study staff" }]

function SAMLFinaliser(props) {
    const { classes, t } = props
    let [showProductList, setProducts] = useState([])
    let [showRoles, setRoles] = useState([])
    let [selectedRole, setSelectedRole] = useState('');
    const getRedirectQueryString = function (errorMessage) {
        let qs = { errorMessage };
        return `?${queryString.stringify(qs)}`;
    }
    const fetchData = async () => {
        try {
            let proceedWithAuthOrProvisionUser = await axios.get('/api/admin/providerauthservice/proceedWithAuthOrProvisionUser', null);
            if (proceedWithAuthOrProvisionUser.status == 200) {
                if (proceedWithAuthOrProvisionUser.data.proceedWithAuth && proceedWithAuthOrProvisionUser.data.tenantId.length == 0) {
                    throw "user does not belong to any product";
                }
                else if (proceedWithAuthOrProvisionUser.data.proceedWithAuth && proceedWithAuthOrProvisionUser.data.tenantId.length == 1) {
                    productSelection(proceedWithAuthOrProvisionUser.data.tenantId[0])
                }
                else if (proceedWithAuthOrProvisionUser.data.proceedWithAuth && proceedWithAuthOrProvisionUser.data.tenantId.length > 1) {
                    setProducts(proceedWithAuthOrProvisionUser.data.tenantId)
                    setRoles([])
                }
                else if (!proceedWithAuthOrProvisionUser.data.proceedWithAuth && proceedWithAuthOrProvisionUser.data.possibleRoles.length == 0) {
                    throw "invalid user role";
                }
                else if (!proceedWithAuthOrProvisionUser.data.proceedWithAuth && proceedWithAuthOrProvisionUser.data.possibleRoles.length > 1) {
                    setRoles(proceedWithAuthOrProvisionUser.data.possibleRoles)
                    setProducts([])
                }
                else if(!proceedWithAuthOrProvisionUser.data.proceedWithAuth && proceedWithAuthOrProvisionUser.data.possibleRoles.length == 1){
                    submitRoleSelection(proceedWithAuthOrProvisionUser.data.possibleRoles[0])
                }
                else {
                    throw "Something went wrong. try again later"
                }
            }
        } catch (error) {
            console.log(error)
            if(error.response){
                props.history.push(`/guest/errorpage${getRedirectQueryString(error.response.data.statusDescription)}`);
            }
            else{
                props.history.push(`/guest/errorpage${getRedirectQueryString(error)}`);
            }
        }
    }
    const productSelection = async(productId)=>{
        try {
            let selectProduct = await axios.get(`/api/admin/providerauthservice/getTenantRedirectURL/${productId}`);
            if (selectProduct.status == 200) {
                let hostURL = window.location.host.includes("localhost");
                window.open(`${selectProduct.data.redirecturl}/processSSO?token=${selectProduct.data.token}`,"_self");
            }
            else{
                throw "something went wrong"
            }
        } catch (error) {
            console.log(error)
            if(error.response){
                if(error.response.status == 422){
                    props.history.push(`/guest/errorpage${getRedirectQueryString(error.response.data.error.details)}`);    
                }else{
                    props.history.push(`/guest/errorpage${getRedirectQueryString(error.response.data.statusDescription)}`);
                }
            }
            else{
                props.history.push(`/guest/errorpage${getRedirectQueryString(error)}`);

            }
        }
    }
    useEffect(()=>{ 
        fetchData();
    }, [])

    const handleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const submitRoleSelection = async (role) => {
        try {
            let provisionUser = await axios.post(`/api/admin/providerauthservice/provisionUser`, {userRole:role});
            if (provisionUser.status == 200) {
                fetchData(); 
            }
            else{
                throw "something went wrong"
            }  
        } catch (error) {   
            console.log(error)         
            if(error.response){
                if(error.response.status == 422){
                    props.history.push(`/guest/errorpage${getRedirectQueryString(error.response.data.error.details)}`);    
                }else{
                    props.history.push(`/guest/errorpage${getRedirectQueryString(error.response.data.statusDescription)}`);
                }
            }
            else{
                props.history.push(`/guest/errorpage${getRedirectQueryString(error)}`);

            }       
        }
    }
    return (
        <div className={classes.wrap}>
            <Card
            className={classes.wrapItem}
            >
                <CardContent
                >
                    <div className={classes.imageWeidth}>
                        <img src={props.currentUser.staticContentUrl+"/config_logo/ProdigyLogo.png"} alt="Product Logo" width="100%"/>
                    </div>
                    {showProductList.length > 1 ?
                        <>
                            <Typography type="title" component="h2" className={classes.title}>{t('login.SELECT_PRODUCT')}</Typography>
                            {
                                productList.filter(element => showProductList.includes(element.productId)).map((element, index) => {
                                    return (
                                        <GridContainer key={index} className={classes.productItem} onClick={() => productSelection(element.productId)}>
                                            <GridItem md={4}>
                                                <img src={element.logo?props.currentUser.staticContentUrl+element.logo:process.env.PUBLIC_URL+'../../images/myHope.png'} alt="Product Logo" width="100%"/>
                                            </GridItem>
                                            <GridItem md={8}>
                                                <Typography className={classes.textSize} key={index} value={element.productId}> {element.productName} </Typography>
                                            </GridItem>
                                        </GridContainer>
                                    )
                                })

                            }

                        </>
                        : ""}
                    {showRoles.length > 1 ?
                        <>
                            <Typography type="title" component="h2" className={classes.title}>{t('login.SELECT_ROLES')}</Typography>
                            <RadioGroup className={classes.radioItems} name="useRole" value={selectedRole} onChange={handleChange}>
                                {
                                    userRoles.filter(element => showRoles.includes(element.userRoleId)).map((element, index) => {
                                        return (
                                            <div key={index}>
                                                <FormControlLabel className={classes.radioLabel} value={element.userRoleId} control={<Radio />} label={element.userRole} />
                                                <br />
                                            </div>
                                        )
                                    })

                                }
                            </RadioGroup>
                            <Button wrapperClass={classes.submitButton} disabled={!selectedRole} onClick={()=>{submitRoleSelection(selectedRole)}} primary submit>
                                {t('login.Create_User')}
                            </Button>
                        </>
                        : ""}
                </CardContent>
            </Card>
        </div>
    )
}

export default withTranslation()(withStyles(styles)(WithUser(SAMLFinaliser)))