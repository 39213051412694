exports.loginRedirectUrl = (redirectTo, userrole) => {
    let redirectUrl = '';
    if (redirectTo) {
        redirectUrl = redirectTo;
    }
    else if (userrole === 'SuperAdmin' || userrole===1) {
        redirectUrl = '/superadmin'
    }
    else if (userrole === 'AccountAdmin' || userrole===2 || userrole===5) {
        redirectUrl = '/admin';
    }
    else if (userrole === 'Provider' || userrole===3) {
        redirectUrl = '/provider';
    }
    else if (userrole === 'CoWorker' || userrole===4) {
        redirectUrl = '/coworker';
    }
    else if (userrole === 'DefaultAccountAdmin') {
        redirectUrl = '/admin';
    }
    return redirectUrl;
};

exports.createUserObject = (userData) => {
    return {
        name: 'abcd',
        isAuthenticated: true,
        role: userData.userrole,
        permissionlevel: userData.permissionlevel,
        firstname: userData.firstname,
        lastname: userData.lastname,
        userTableId: userData.userTableId,
        userid: userData.userid,
        cellnumber: userData.cellnumber,
        country: userData.country,
        onboarding: userData.onboarding,
        image: userData.image,
        termsAndConditionVersion: userData.termsAndConditionVersion,
        privacyVersion: userData.privacyVersion
    };
};
exports.getSurveyCount = (prevState, currentState) => {
    let count = 0
    if (Array.isArray(currentState.store) && currentState.store.length) {
        currentState.store.forEach((item) => {
            let a = item.validFrom.split("-");
            let d = new Date(a[0], a[1] - 1, a[2])
            let e = item.validUpto.split("-");
            let c = new Date(e[0], e[1] - 1, e[2])
            let Todaydate = new Date();
            Todaydate.setHours(0, 0, 0, 0)
            if (Todaydate >= d && Todaydate <= c && (item.status !== 3)) {
                count = count + 1;
                return count;
            }
        })
    }
    return count;
};