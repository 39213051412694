import React from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import Button from '../../components/CustomComponents/Button';
import Typography from '../../components/CustomComponents/Typography';
import { withStyles, InputAdornment, FormControlLabel } from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CardFooter from "../../components/Card/CardFooter.jsx";
import WithUser from "../../ContextAndHOC/WithUser";
import { Formik, Form, Field } from 'formik';
import { string, object, number } from 'yup';
import FormikTextField from '../../components/FormikValidatedComponents/TextField';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import Spinner from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { loginRedirectUrl, createUserObject } from './CommonFunction';
import { getStyleForOTPScreen } from '../../components/CustomStyles/PublicPageStyles.jsx';
const styles = theme => getStyleForOTPScreen(theme);

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        const { t } = props;
        this.yupSchema = object().shape({
            id: string().max(78, t('admin.A__EMAILID_MAX_LIMIT_MESSAGE')).required(t('login.L__LOGIN__LOGIN_ERROR')).email(t('superAdmin.SA__SUPER__EMAIL_ERROR')),
            security: string().required(t('login.L__LOGIN__LOGIN_ERROR'))
        });
        this.otpSchema = object().shape({
            otp: number().typeError(t('login.L__OTP_MUSTBEANUMBER')).required(t('login.L__LOGIN__LOGIN_ERROR'))
        });
        this.state = { locked: false, otpSpinner: false, isLoginCompleted: false, renderto: '', loginSucess: false, userError: '', forceRedirect: false, country: '', password: "password", showPassword: true, hasOtp: null, remember: false, wrong_otp: false, otpResend: false, otpLogin: false, expire_otp: false };
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    };
    handleChange = (e) => {
        if (e.target.checked === true) this.setState({ remember: true });
        if (e.target.checked === false) this.setState({ remember: false });
    };

    otpHandleSubmit = async (values) => {
        this.setState({ otpLogin: true, otpSpinner: true })
        let offset = new Date().getTimezoneOffset() * -1;
        try {
            let response = await axios.post('/api/admin/providerauthservice/login', {
                otp: values.otp,
                offset: offset,
                type: 2,
                rememberMe: this.state.remember,
                token: localStorage.getItem('otpToken')
            });
            values.otp = '';
            let { redirectTo } = queryString.parse(this.props.location.search);
            if (response.data.status === 1) {
                this.setState({ isLoginCompleted: false });
                localStorage.setItem("refreshToken", response.data.refreshToken);
                if (!response.data.userrole) {
                    let role = response.data.role;
                    this.setState({ renderto: '/firsttimelogin/' + role, forceRedirect: true });
                    this.props.history.push("/firsttimelogin/"+role);
                }
                else {
                    this.setState({ renderto: loginRedirectUrl(redirectTo, response.data.userrole) });
                    this.props.currentUser.refreshTokens();
                    this.props.currentUser.changeUser(createUserObject(response.data));
                    this.setState({ loginSucess: true });
                }
            }
        }
        catch (err) {
            if (err.response.data.warningmessage === this.props.t('login.FIFTH__TIME__WRONG_ATTEMPT')) {
                this.setState({ isLoginCompleted: false, forceRedirect: true, renderto: '/guest/locked', otpSpinner: false })
            }
            let errMessage = <>{err.response.data.warningmessage}</>
            this.setState({ isLoginCompleted: false, userError: errMessage, otpSpinner: false })
        }
    }

    handleClick = (e) => {
        e.preventDefault();
        axios.post('/api/admin/providerauthservice/resendotp', {
            token: localStorage.getItem('otpToken')
        }).then(res => {
            if (res.status === 200) {
                this.setState({
                    otpResend: true
                })
            }
        });
    }

    handleShowPassword = () => {
        this.setState({ password: "text", showPassword: false });
    }
    handleHidePassword = () => {
        this.setState({ password: "password", showPassword: true });
    }

    getRedirectQueryString = function (redirectTo) {
        let qs = { redirectTo };
        return `?${queryString.stringify(qs)}`;
    }
    handleonfocus = () => {
        return "this.removeAttribute('readonly');";
    }
    render() {
        const { classes, t } = this.props;
        const loginCompleted = (this.props.currentUser.userDetails.isAuthenticated && this.state.loginSucess);
        return (
            <>
                <Helmet>
                    <title>
                        {t('login.L__LOGIN_TITLE_TEXT',{'#PRODUCT_NAME':this.props.currentUser.productName})}
                    </title>
                </Helmet>
                {this.state.renderto === "/guest/locked" && <Redirect to={this.state.renderto} />}
                {
                    loginCompleted ? (
                        <Redirect
                            to={`/accountselection${this.getRedirectQueryString(
                                this.state.renderto
                            )}`}
                        />
                    ) : (
                        <Formik
                            initialValues={{ otp: '' }}
                            onSubmit={this.otpHandleSubmit}
                            validationSchema={this.otpSchema}
                        >
                            {props => (
                                <Form>
                                    <div>
                                        <Typography className={classes.LoginHeading} type="title">
                                            {t("login.L__OTP_HEADER_TEXT",{'#PRODUCT_NAME':this.props.currentUser.productName})}
                                        </Typography>
                                        <Typography className={classes.LoginSubHeading} type="body1">
                                            {t("login.L__OTP_HEADER_MESSAGE")}
                                        </Typography>
                                        <br />
                                        <Typography className={classes.email_miss} component="div" type="body1">
                                            {(!this.state.otpResend) ? (<Typography component="span" type="body1">{t("login.L__OTP_EMAIL_MISS")}</Typography>) : (<Typography component="span" type="body1">{t("login.L__OTP_EMAIL_RESEND")}</Typography>)}
                                            <Link onClick={this.handleClick} className={classes.msePointer}>{t("login.L__OTP_RESEND")}</Link>
                                        </Typography>
                                        <br />
                                        {(this.state.locked) && (
                                            <div className={classes.rectangle}>
                                                <p className={classes.errorText}>{t("login.L__OTP_EXPIRE_MSG")}</p>
                                            </div>
                                        )}
                                        <Field
                                            component={FormikTextField}
                                            value={props.values.otp || ''}
                                            variant="filled"
                                            label={t("login.L__LOGIN__OTP")}
                                            fullWidth
                                            name="otp"
                                            validate={this.state.wrong_otp}
                                            placeholder={t("login.L__OTP_PLACEHOLDER")}
                                            InputProps={{
                                                autoComplete: "off",
                                                className: classes.ieRemoveIcon
                                            }}
                                            readOnly
                                            onFocus={this.handleonfocus}
                                        />
                                        <div className={classes.textLeft}>
                                        {(!this.props.displayTrustComputerRadioButton) && (<Link className={classes.forgotLink+" "+classes.icnColor} onClick={() => {localStorage.removeItem('otpToken');this.props.history.push(`./login`)}}> {t('login.L__LOGIN_BACK')}</Link>)}             
                                        </div>
                                        {(this.props.displayTrustComputerRadioButton) && (<FormControlLabel
                                            control={
                                                <Checkbox color='primary' icon={<RadioButtonUncheckedIcon fontSize={"default"} />} checkedIcon={<RadioButtonCheckedIcon fontSize={"default"} />} onChange={this.handleChange} />
                                            }
                                            onChange={this.handleChange}
                                            classes={{ label: classes.fifteen }}
                                            label={<Typography type="body1" component="label">{t('login.L__OTP_TRUST')}</Typography>}
                                        />)}
                                        <p style={{ color: "#d51035" }} >{this.state.userError}</p>
                                        <CardFooter
                                            className={classes.cardFooter}
                                            disabled={props.isSubmitting}
                                            style={{ marginBottom: '25px' }}
                                        >
                                            <br />
                                            <Button type="submit" primary dashboard loading={this.state.isLoginCompleted || this.state.otpSpinner} className={classes.primaryBtn} size="small">
                                                {t('login.L__OTP_NEXT')}
                                            </Button>
                                        </CardFooter>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )
                }
            </>
        );
    }
}

export default withTranslation()(withStyles(styles)(WithUser(LoginPage)));
