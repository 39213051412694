import React, {useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Hidden, IconButton, Typography, Toolbar, AppBar, ListItem, Divider, List, SwipeableDrawer } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { withTranslation } from 'react-i18next';
import withUser from "../ContextAndHOC/WithUser";

const styles = theme => ({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    menuButton: {
        // marginLeft: -12,
        marginRight: 20,
        height: 46,
        position: "absolute",
        left: "2%",
        color: theme.palette.primary.main
    },
    root: {
        flexGrow: 1,
        marginTop: "72px"
    },
    logoutBtn: {
        textDecoration: "none",
        color: "#515974",
        display: "inline-flex",
        float: "right",
        textTransform: "capitalize",
        marginRight: "2%",
        paddingTop:"10px",
        "&:hover": {
            fontWeight: "bolder",
            color: theme.custom.activeNavLink,
        }
    },
    logoBg: {
        backgroundColor: theme.custom.navbarImageBg, padding: "17px 37px 7px"
    },
    logoForMobile: {
        left: "30%",
        height: "80px",
        position: "absolute",
        padding: "17px 10px 7px",
        [`& img`]: {
            width: "150px"
        }
    },
    dispBlk:{
        [theme.breakpoints.down('sm')]:{
            display:"block",
            width:"100%"
        }
    },
    navLinkMobile: { padding: "2px 5px", textDecoration: "none", display: "inline-flex", float: "left", color: "#515974" },
    appBar:{boxShadow:"none !important",backgroundColor: '#fff'}
});

function Navbar(props) {
    const [state, setState] = React.useState({
        left: false
    });
    const { classes, providerName, homeLink, t } = props;

    const toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ state, [side]: open });
    };
    useEffect(() => {
        if(props.isLogout){
            logout();
        }        
      },[props.isLogout]);

      const logout=()=>{
        props.currentUser.changeUser();
        props.history.push('/');    
      }

    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            <List>
                <ListItem>
                    <Typography variant="h6"> {providerName}</Typography>
                </ListItem>
                <Divider />
                {props.Nav.map((links, i) =>
                    <div key={i}><ListItem ><div className={classes.dispBlk}>{links}</div></ListItem></div>
                )}
                <ListItem >
                    <div style={{ width: "100%" }}>
                        <NavLink to={`#`} onClick={logout} className={classes.navLinkMobile+" "+classes.dispBlk}>{t('superAdmin.SA__SUPER__ADMIN__LOGOUT_BUTTON')}</NavLink>
                    </div>
                </ListItem>
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar style={{ padding: "0" }}>
                    <Hidden smDown>
                        <Typography variant="h6" color="inherit" className={classes.logoBg} >
                            <NavLink to={homeLink} className={classes.navLink} activeClassName="active" activeStyle={{ fontWeight: "bold", color: "red" }}>
                                <img src={props.theme.custom.navbarImage} alt="logo" width="124px"/>
                            </NavLink>
                        </Typography>
                    </Hidden>
                    <Hidden mdUp>
                        <Typography variant="h6" color="inherit" className={classes.logoForMobile} >
                            <NavLink to={homeLink} className={classes.navLink} activeClassName="active" activeStyle={{ fontWeight: "bold", color: "red" }}>
                                <img src={props.theme.custom.navbarImageMobile} alt="logo" />
                            </NavLink>
                        </Typography>
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={toggleDrawer('left', true)}>
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                    <Hidden smDown>
                        <div style={{ width: "100%" }}>
                            {/* <NavLink to={`#`} onClick={logout} className={classes.logoutBtn} >{t('superAdmin.SA__SUPER__ADMIN__LOGOUT_BUTTON')}</NavLink> */}
                            {props.Nav.map((links, i) =>
                                <div key={i}>{links}</div>
                            )}

                        </div>
                    </Hidden>
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                open={state.left}
                onClose={toggleDrawer('left', false)}
                onOpen={toggleDrawer('left', true)}
            >
                {sideList('left')}
            </SwipeableDrawer>
        </div>
    );

}

Navbar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(withUser(Navbar)));
