import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import React from 'react';
import "./index.css";
import Analytics from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';
import axios from 'axios';
import { awsPinPointConfig, sessionConfig, pageOptions, initialisePinPointConfig} from './pinpointConfig'
import RenderAppBasedOnInternet from './RenderAppBasedOnInternet';

let getConfig = async () => {
    let result = await axios.get('/api/admin/providerauthservice/getconfig');
    initialisePinPointConfig(result.data.pinpoint.idpId,result.data.pinpoint.region,result.data.pinpoint.appId)
    Auth.configure(awsPinPointConfig()['Auth']);
    Analytics.configure(awsPinPointConfig()['Analytics']);
    Analytics.autoTrack('session', sessionConfig);
    Analytics.autoTrack('pageView', pageOptions);
    return result.data;
}



getConfig().then(function (configObject) {
            ReactDOM.render( <RenderAppBasedOnInternet configObject = {configObject}/>, document.getElementById('root'));}).catch(function (err) {
            console.log(err);
            alert('Unable to download config. Fatal error.')
        });