import React from "react";
import RadioGroup from '@material-ui/core/RadioGroup';

export default function ({children, ...otherProps}){

    return (
        <RadioGroup {...otherProps}>
           {children}
        </RadioGroup>
    )
}