import React, { Component } from 'react';
import CardFooter from "../../components/Card/CardFooter.jsx";
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core';
import Typography from '../../components/CustomComponents/Typography';
import Button from '../../components/CustomComponents/Button';
import FormikTextField from '../../components/FormikValidatedComponents/TextField';
import axios from 'axios';
import { Formik, Form, Field,connect } from 'formik';
import WithUser from "../../ContextAndHOC/WithUser";
import { string, object } from 'yup';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import { getStyleForForgotPassword } from '../../components/CustomStyles/PublicPageStyles.jsx';
const styles = theme => getStyleForForgotPassword(theme);

class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        const { t } = props;
        this.yupSchema = object().shape({
            email: string().max(78, t('admin.A__EMAILID_MAX_LIMIT_MESSAGE')).required(t('login.L__LOGIN__LOGIN_ERROR')).email(t('login.L__LOGIN__LOGIN_EMAIL_ERROR'))
        })
        this.state = {
            resetpasswordmsg: false, popUpMsg: '', emailRegistered: "",emailid:""
        }
    }

    handleSubmit = (values, { setSubmitting }) => {
        axios.post('/api/admin/providerauthservice/forgotPassword', { emailid: values.email }
        ).then(result => {
            if (result.data.status === 1) {
                this.setState({ resetpasswordmsg: true, emailid: values.email });
                setSubmitting(false);
            }
            else if (result.data.status === 0) {
                this.setState({
                    emailRegistered: result.data.statusDescription,
                });
                setSubmitting(false);
            }
        })
    }

    handleResetPassword = () => {
        this.setState({ resetpasswordmsg: false });
    }
    dismissDialog = () => {
        this.props.history.push(`../login`)
    }
    render() {
        const { classes, t } = this.props;
        return (
            <div >
                <Helmet>
                    <title>
                        {t('login.L__LOGIN_FORGET_TITLE_TEXT',{'#PRODUCT_NAME':this.props.currentUser.productName})}
                    </title>
                </Helmet>
                {(!this.state.resetpasswordmsg ? <Formik initialValues={{ email: "" }} onSubmit={this.handleSubmit} validationSchema={this.yupSchema}
                >
                    {(props) => (
                        <Form className={classes.root}>
                            <Typography className={classes.passwordTitle} type="title"> {t('forgotPassword.F__FORGOT__FOR_GOT_YOUR_PASSWORD')}</Typography>
                            <Typography className={classes.fontSize16} type="body1" component="p">{t('forgotPassword.F__FORGOT__FOR_GOT_PASSWORD_MESSAGE',{'#PRODUCT_NAME':this.props.currentUser.productName})}</Typography>
                            <Field
                                name="email"
                                fullWidth
                                label={t('login.L__LOGIN__EMAIL')}
                                placeholder={t('login.L__LOGIN__EMAIL')}
                                variant="filled"
                                InputProps={{
                                    type: "email"
                                }}
                                component={FormikTextField}
                                
                            />
                            <div className={classes.invalidUser}>{this.state.emailRegistered}</div>
                            <CardFooter style={{ display: "block" }} className={classes.padTop0}>
                            <br /><br/>
                                <Button type="submit" primary className={classes.primaryBtn} disabled={props.isSubmitting} size="small">
                                    {t('common.COMMON__CONFIRM_BUTTON')}
                                </Button>
                                <br />
                                <Typography className={classes.rememberMe} type="body1">{t('login.P__LOGIN_REMEMBER_IT')}<Link className={classes.backLoginLink} to={"/guest/Login/"}>&nbsp; {t('forgotPassword.F__FORGOT__FOR_GOT_BACK')}</Link></Typography>
                            </CardFooter>
                        </Form>
                    )}
                </Formik> : <Card style={{ boxShadow: "none", width:343 }}>
                        <CardBody>
                            <Typography className={classes.LoginHeading} type="title">{t('forgotPassword.P__RECOVER_PASSWORD')}</Typography><br />
                            <Typography variant="h4" color="inherit" className={classes.loginSuccess} type="body1">{t('forgotPassword.F__RESET_INSTRUCTION')}<Typography component="span" className={classes.font18}>{this.state.emailid}</Typography></Typography><br/>
                            <Typography variant="h4" color="inherit" className={classes.loginSuccess} type="body1">{t('forgotPassword.F__RECOVER_TEXT')}</Typography><br/>
                        </CardBody>
                        <CardFooter className={classes.cardFooter} >
                            <Typography className={classes.font15} type="body1">{t('forgotPassword.F__EMAIL_RECIEVE')}</Typography>
                            <Button className={classes.primaryBtn} primary size="small" onClick={this.dismissDialog}>
                                {t('login.L__LOGIN_BUTTON')}
                            </Button>
                        </CardFooter>
                    </Card>)}
            </div>
        )
    }
}

export default withTranslation()(withStyles(styles)(WithUser(ForgetPassword)));