import React from 'react'
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getStyleForLockedScreen } from '../../components/CustomStyles/PublicPageStyles.jsx';
import Typography from '../../components/CustomComponents/Typography.jsx';
const styles = theme => getStyleForLockedScreen(theme);
class Locked extends React.Component {
    render() {
        const { t, classes } = this.props;
        return (
            <div key={this.props.i18n.language} //to make sure that page is re initialised if language is changed
                style={{
                    paddingTop: "6%",
                    paddingBottom: "15%",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classes.maxWidth}>
                    <Typography className={classes.lockdTit} type="title" variant="h1">{t('common.COMMON__LOCKED_TITLE')}</Typography>
                    <Typography className={classes.lockdBdy} type="body1">{t('common.COMMON__LOCKED_BODY')}</Typography>
                    <hr style={{borderColor:"rgba(34, 98, 191, 1)",borderWidth: "0.5px"}}/>
                    <Typography className={classes.lockdfoot} type="body1"><Link className={classes.resentLink} to={"/guest/ForgotPassword"}> Click here</Link> to reset your password.</Typography>
                </div>
            </div>
        )
    }
}

export default withTranslation()(withStyles(styles, { withTheme: true })(Locked));
// export default UnableToLogin;