import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Button } from "@material-ui/core";

const styles = makeStyles((theme) => ({
    primary: { color: theme.palette.text.primaryButtonTextColor, backgroundColor: theme.palette.primary.main, 
        border: "1px solid " + theme.palette.primary.main, textTransform: "uppercase", 
        '&:hover': {backgroundColor: theme.palette.primary.main,opacity:0.8},
        [theme.breakpoints.down("sm")]: {
            minWidth: "100%"
        },
        '&:disabled':{ backgroundColor: theme.custom.buttonDisabledBg, color:theme.custom.buttonDisabledTextClr, border: "1px solid "+theme.custom.buttonDisabledTextClr}
     },
    secondary:{ textTransform: "uppercase", backgroundColor: theme.palette.text.primaryButtonTextColor, color: theme.palette.primary.main, border: "1px solid "+theme.palette.primary.main, fontWeight: "500 !important", 
        '&:hover': {backgroundColor: theme.palette.text.primaryButtonTextColor,opacity:0.8},
        [theme.breakpoints.down("sm")]: {
            minWidth: "100%",
            marginLeft:0
        },
        '&:disabled':{ backgroundColor: theme.custom.buttonDisabledBg, color:theme.custom.buttonDisabledTextClr, border: "1px solid "+theme.custom.buttonDisabledTextClr}
    },
    customDashboard:{borderRadius: "50px", margin: "8px 0", minWidth: "218px !important", textAlign: "right", padding: "7px 20px",},
    customSubmit:{borderRadius: "25px", minWidth:"242px !important", padding:"5px 0"},
    customDialog:{borderRadius: "50px", padding: "3px 35px"},
    wrapper: { margin: theme.spacing(1), display:"inline-block", position: "relative"},
    buttonProgress: { position: "absolute", top: "50%", left: "50%", marginTop: -12, marginLeft: -12 },
}));

export default (props) => {
    const classes = styles();
    const { loading, children,primary, secondary, dashboard, dialog, submit, className, wrapperClass, ...other } = props;
    const buttonColorHandler = () => {
        if (primary) { return classes.primary; }
        else if (secondary){ return classes.secondary; }
    }
    const buttonLocationHandler = () =>{
        if (dashboard) { return classes.customDashboard; }
        else if (submit) { return classes.customSubmit; }
        else if (dialog) { return classes.customDialog}
    }
    
    return (
        <div className={classes.wrapper+" "+ wrapperClass}>
            <Button
                className={buttonColorHandler()+" "+buttonLocationHandler()+" "+ className}
                disabled={loading}
                {...other}
            >
                {children}
            </Button>
            {loading && (
                <CircularProgress size={24} className={classes.buttonProgress} />
            )}
        </div>
    );
};