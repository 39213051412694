import React from 'react';
import { withStyles } from '@material-ui/core';
import Typography from './CustomComponents/Typography'
import { withTranslation } from 'react-i18next';

const styles = theme =>({
        footer:{
        margin:'0px',
        padding:"15px",
        textAlign:"center",
        fontSize: "12px !important",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "2",
        letterSpacing: "-0.14px",
        color: theme.palette.text.textFourthColor
        },
        root: {
        flexGrow: 1,
        marginBottom:"50px",
        zIndex:1290,
        [theme.breakpoints.up('sm')]:{
            flexGrow: 1,
            bottom: 0,
            position: "sticky",
            marginBottom: 0,
            left: 0,
        },
        [theme.breakpoints.down('sm')]:{
            marginBottom: 50,
        },
        '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)':{
            position: "fixed",

        },
        width:"100%"
        },
        pos:{ position: 'absolute'}

})

function Footer(props){
    const { t, classes } = props;
    const path = props.props ? props.props.location.pathname : "";
    let isStudy = props.props?props.props.currentUser.isStudy:false;
    return(
        <div className={`${classes.root} ${path.includes("resetpassword") && classes.pos}`} id="printFooter">
        <Typography className={classes.footer} position="static" style={{ backgroundColor: 'white' }}>{isStudy?t('prescribe.P__FOOTER_EIGTH_LINE'):''}</Typography>
        </div>
    )
}

export default withTranslation()(withStyles(styles)(Footer));