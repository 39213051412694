import React from 'react';
import { withStyles } from '@material-ui/core';
import Button from '../../components/CustomComponents/Button';
import Typography from '../../components/CustomComponents/Typography'
import { withTranslation } from 'react-i18next';
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import AuthenticatedPage from "../../ContextAndHOC/AuthenticatedPage";
import queryString from 'query-string'
import Footer from '../../components/Footer';
import cautionImg from '../../assets/images/caution.png';
import { getStyleForFirstScreen } from '../../components/CustomStyles/PublicPageStyles.jsx';
const styles = theme => getStyleForFirstScreen(theme);

class FirstScreen extends React.Component {

    handleContinue = () => {
        let { redirectTo } = queryString.parse(this.props.location.search);
        this.props.history.push(`${redirectTo}/${this.props.match.params.selectedAccountId}`);
    }
    render() {
        const { classes, t } = this.props;
        return (
            <React.Fragment>
                <div className={classes.root}>
                    <GridContainer className={classes.vCenter}>
                        <img src={this.props.theme.custom.navbarImage} alt="logo" width="340px" className={classes.stepperMargin} />
                        <GridItem>
                            <div className={classes.versionWrap}>
                                <Typography type="body3" className={classes.refElement}>{t('publicJs.P__LOT')}</Typography>
                                <Typography type="body2" className={classes.versionText}>{t('publicJs.P__PUBLIC_VERSION')} <br /><Typography component="span" type="body3" className={classes.versionNumber}>{process.env.REACT_APP_VERSION !== undefined ? process.env.REACT_APP_VERSION : "xxx.xx.xx"}</Typography></Typography>
                            </div>
                        </GridItem>
                        <GridItem>
                            <br />
                            {this.props.currentUser.isStudy && <div className={classes.warningWrap}>
                                <img src={cautionImg} width="23px" height="23px" />
                                <Typography className={classes.body2}> <Typography component="span" type="body2" className={classes.warningTxt}>{t('common.COMMON__WARNING')}</Typography> <br /><Typography component="span" type="body3" className={classes.cautionTxt}>{t('publicJs.P__PUBLIC_CAUTION')}-{t('publicJs.P__PUBLIC_INVESTIGATIONAL')}</Typography></Typography>
                            </div>}
                        </GridItem>
                        <GridItem >
                            <div className={classes.continueButton}>
                                <Button variant="contained" onClick={this.handleContinue} className={classes.robotoTxt} primary dashboard>{t('onboarding.ONBOARDING_CONTINUE_BUTTON')}</Button>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <Footer styles={{ backgroundColor: 'white' }} />
            </React.Fragment >
        )
    }
}

export default withTranslation()((withStyles(styles, { withTheme: true }))(AuthenticatedPage()(FirstScreen)));