import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { withStyles } from "@material-ui/core";
import Typography from '../../components/CustomComponents/Typography'
import Login from "./LoginPage";
import ErrorPage from "./ErrorPage";
import ForgetPassword from "./ForgetPassword";
import ResetPassword from "./ResetPassword";
import Locked from "./Locked";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import { withTranslation } from 'react-i18next';
import OTP from './OTP';
import { getStyleForPublicPage } from '../../components/CustomStyles/PublicPageStyles.jsx';
const styles = theme => getStyleForPublicPage(theme);

class PublicPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {displayTrustComputerRadioButton:false,email:''};      
    }
   handleTrustComputer = (value)=>{
    this.setState({displayTrustComputerRadioButton: value})
   }
   handleEmailInput = (value) =>{
       this.setState({email: value})
   }
    render() {
        const { classes, t, isStudy } = this.props;
        return (
            <div key={this.props.i18n.language}>
                <GridContainer style={{ backgroundColor: "#fff", height:'100%' }}>
                    <GridItem lg={4} md={4}
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/images/${this.props.theme.custom.loginBgImage})`,
                            // backgroundRepeat: "repeat-y",
                        }}>
                        <div className={classes.padTop}>
                            {this.props.sidePanelText.title && <h2 className={`${classes.clrWhite} ${classes.fntSze20}`}>{this.props.sidePanelText.title}</h2>}
                            {this.props.sidePanelText.title && <div style={{ backgroundColor: "#fff", height: "1px", width: "50px" }}></div>}<br/><br/>
                            {this.props.sidePanelText.body1 && <p className={classes.clrWhite + " " + classes.fntSze19} style={{marginBottom:40}}>{this.props.sidePanelText.body1}</p>}
                            {this.props.sidePanelText.body2 &&<p className={classes.clrWhite + " " + classes.fntSze19}>{this.props.sidePanelText.body2}</p>}
                        </div>
                    </GridItem>
                    <GridItem lg={2} md={2} style={{height:'100vh'}}>
                        <div>
                            <br /><br />
                            <img alt="logo" src={this.props.theme.custom.loginLogo!=="myHope.png"?this.props.theme.custom.loginLogo:process.env.PUBLIC_URL + '/images/' + this.props.theme.custom.loginLogo} className={this.props.classes.logoSize} />
                        </div>
                    </GridItem>
                    <GridItem lg={6} md={6} style={{height:'100%'}}>
                        <div className={classes.login}>
                            <Switch>
                                <Route path={this.props.match.url + "/ResetPassword"} component={ResetPassword} />
                                <Route path={this.props.match.url + "/locked"} component={Locked} />
                                <Route path={this.props.match.url + "/ForgotPassword"} component={ForgetPassword} />
                                <Route path={this.props.match.url + "/Login"} render={props => <Login handleTrustComputer = {this.handleTrustComputer} handleEmailInput = {this.handleEmailInput} emailInput={this.state.email} {...props}/>}/>
                                <Route path={this.props.match.url + "/errorpage"} component={ErrorPage} />
                                <Route path={this.props.match.url + "/Otp"}   render={(props) => <OTP displayTrustComputerRadioButton={this.state.displayTrustComputerRadioButton} {...props}/>}/>
                                <Redirect to={this.props.match.url + "/Login"} />
                            </Switch>
                        </div>
                        <div className={classes.footerWrap} style={{ marginTop: '5' }}>
                            <Typography className={classes.footer}>
                                <small>{isStudy?t('prescribe.P__FOOTER_EIGTH_LINE'):''}</small>
                            </Typography>
                        </div>
                    </GridItem>
                </GridContainer>
            </div >
        );
    }
}

export default withTranslation()(withStyles(styles, { withTheme: true })(PublicPage));
