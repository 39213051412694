import React from "react";
import { TextField, FormHelperText } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme=>({
  
}));

export default function ({children,...otherProps}) {
    const classes = useStyles();
    return (
        <>
          <TextField
            {...otherProps}
          />
          {children}
        </>
      );
}