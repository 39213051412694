import React from 'react';
import { withStyles, Card, CircularProgress, Menu, MenuItem, Button, Avatar } from '@material-ui/core';
import Typography from '../../components/CustomComponents/Typography'
import { withTranslation } from 'react-i18next';
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import AuthenticatedPage from "../../ContextAndHOC/AuthenticatedPage";
import Footer from '../../components/Footer';
import ActionButton from './ActionButtonAccountSeelction';
import MuiThemeDataTable from '../../components/MuiThemeDataTable';
import Navbar from '../../components/Navbar';
import logout from "../../assets/images/logout.png";
import AdminImage from '../../assets/images/patientsProfile.png';
import { getStyleForAccountSelection } from '../../components/CustomStyles/PublicPageStyles.jsx';
const styles = theme => getStyleForAccountSelection(theme);

class AccountSelectionScreen extends React.Component {
    constructor(props) {
        super(props);
        this.fieldVariables = { selectedAccount: '' }
        this.state = {
            isLoading: false,
            errorDialog: false,
            loadingStart: false,
            activeAccountList: [],
            pendingAccountList: [],
            isLogout: false,
            anchorEl: null
        }
    }
    handleAccountSelection = (accountId) => {
        this.props.history.push(`firstscreen/${accountId}${this.props.location.search}`);
    }
    tableheads1 = [
        {
            name: "accountName",
            label: <Typography type="body1">{this.props.t('superAdmin.SA__SUPER__ADMIN__ACCOUNT_NAME')}</Typography>,
            options: {
                filter: false,
                sort: true,
                searchable: true,
                customBodyRender: (value) => {
                    return <p className={this.props.classes.tableHeadWidth}><b>{value}</b></p>
                }
            }
        },
        {
            name: "Action",
            label: <Typography type="body1" style={{textAlign:"center"}}>{this.props.t('dashboard.DASHBOARD__ACTION')}</Typography>,
            options: {
                filter: false,
                sort: false,
                print: false,
                customBodyRender: (value) => {
                    return (
                        <ActionButton accountId={value.accountId} status={value.status} handleAccountSelection={this.handleAccountSelection} handleAcceptInvitation={this.handleAcceptInvitation} />
                    )
                }
            }
        },
    ];


    componentDidMount = async () => {
        let activeAccounts = [];
        let pendingAccounts = [];
        let renderTo;
        let response = await this.props.authenticatedApiCall('get', '/api/admin/admin/getAccountDetailsBelongToUser', null);
        if (response.status === 200) {
            response.data.forEach(item => {
                item.Action = {
                    accountId: item.accountid,
                    status: item.status
                }
                if (item.status === 0) {
                    pendingAccounts.push(item);
                } else if (item.status === 1) {
                    activeAccounts.push(item);
                }
            });
            if (pendingAccounts.length == 0 && activeAccounts.length == 1) {
                renderTo = `${this.props.match.path}/${response.data[0].accountid}${this.props.location.search ? this.props.location.search : ""}`;
                this.props.history.push(`firstscreen/${response.data[0].accountid}${this.props.location.search}`);
            }
            this.setState({ isLoading: true, activeAccountList: activeAccounts, pendingAccountList: pendingAccounts })
        }
    }

    handleAcceptInvitation = async (accountId) => {
        this.setState({ loadingStart: true });
        let statusResult = await this.props.authenticatedApiCall("post", `/api/admin/admin/${accountId}/acceptInvitation`, {});
        if (statusResult.status === 200) {
            //refresh the page
            window.location.reload();
            this.setState({ loadingStart: false });
        } else {
            this.setState({ loadingStart: false, errorDialog: true });
        }
    }
    handleLogout = () => {
        this.setState({ isLogout: true })
    }
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handlepopClose = () => {
        this.setState({ anchorEl: null });
    };
    render() {
        const { classes, t } = this.props;
        const { activeAccountList, pendingAccountList } = this.state;
        const { anchorEl } = this.state;
        let Nav = [
            <Button
                color="inherit"
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
                className={classes.settingLink}
            >
                <Typography style={{ marginRight: "15px" }}>{this.props.currentUser.userDetails.firstname + " " + this.props.currentUser.userDetails.lastname} </Typography>
                <Avatar alt="No Images" src={AdminImage} className={this.props.classes.avatar} />
            </Button>,
            <Menu className={classes.root}
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handlepopClose}
                className={classes.elevationPopover}
                PaperProps={{className:classes.navMenuWrap+" "+"popOverListStyle"}}
                elevation={1}
            >
                <MenuItem onClick={this.handleLogout}><img src={logout} width="15px" />&nbsp;&nbsp;&nbsp;<span style={{color:"#b10222"}}>{t('superAdmin.SA__SUPER__ADMIN__LOGOUT_BUTTON')}</span></MenuItem>
            </Menu>
        ]
        return (
            <React.Fragment>
                <Navbar Nav={Nav} history={this.props.history} homeLink={`/accountselection${this.props.location.search}`} isLogout={this.state.isLogout}/>
                {this.state.isLoading ? <div>
                    <Card className={classes.cardRoot}>
                        {activeAccountList.length>0&& 
                            <GridContainer>
                                <GridItem lg={12} md={12} sm={12} xs={12}>
                                    <Typography type="title" className={classes.headingFont}>{t('superAdmin.SA__SUPER__ADMIN_SWITCH_ACCOUNT')}</Typography>
                                    <MuiThemeDataTable rows={activeAccountList} columns={this.tableheads1} hideDownloadIcon={false} hideSearchIcon={false} hideFilterIcon={false} viewColumnsIcon={false} />                                            
                                </GridItem>
                            </GridContainer>}
                            <br></br>
                            {pendingAccountList.length>0&&<GridContainer>
                                <GridItem lg={12} md={12} sm={12} xs={12}>
                                    <Typography type="title" className={classes.headingFont}>{t('superAdmin.SA__SUPER__ADMIN_PENDING_ACCOUNT')}</Typography>
                                    <MuiThemeDataTable rows={pendingAccountList} columns={this.tableheads1} hideDownloadIcon={false} hideSearchIcon={false} hideFilterIcon={false} viewColumnsIcon={false} />
                                </GridItem>
                            </GridContainer>}
                    </Card>
                </div> : <CircularProgress color="primary" style={{ position: "absolute", top: "50%", left: "50%" }} />}
                <Footer styles={{ backgroundColor: 'white' }} />
            </React.Fragment >
        )
    }
}

export default withTranslation()((withStyles(styles, { withTheme: true }))(AuthenticatedPage()(AccountSelectionScreen)));
