import React from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { Link, Redirect } from 'react-router-dom';
import { withStyles, InputAdornment } from "@material-ui/core";
import Typography from '../../components/CustomComponents/Typography';
import Button from '../../components/CustomComponents/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import CardFooter from "../../components/Card/CardFooter.jsx";
import WithUser from "../../ContextAndHOC/WithUser";
import { Formik, Form, Field } from 'formik';
import { string, object, boolean } from 'yup';
import FormikTextField from '../../components/FormikValidatedComponents/TextField';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { loginRedirectUrl, createUserObject } from './CommonFunction';
import { getStyleForLoginScreen } from '../../components/CustomStyles/PublicPageStyles.jsx';
const styles = theme => getStyleForLoginScreen(theme);

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        const { t } = props;
        this.state = { colorObject: {}, displayPasswordScreen: false, isLogin: false, isLoginCompleted: false, renderto: '', loginSucess: false, userError: '', forceRedirect: false, country: '', password: "password", showPassword: true, hasOtp: null, renderErrorFromSendotpAPI: false };
        this.yupSchema = object().shape({
            email: string().max(78, t('admin.A__EMAILID_MAX_LIMIT_MESSAGE')).required(t('login.L__LOGIN__LOGIN_ERROR')).email(t('superAdmin.SA__SUPER__EMAIL_ERROR')),
            password: string().when('displayPasswordScreen',
                {
                    is: () => this.state.displayPasswordScreen,
                    then: string().required(t('login.L__LOGIN__LOGIN_ERROR'))
                })
        });
    }
    handleSubmit = async (values) => {
        this.setState({ isLoginCompleted: true });
        let offset = new Date().getTimezoneOffset() * -1;
        let { redirectTo } = queryString.parse(this.props.location.search);
        if (!this.state.displayPasswordScreen) {
            let response = await axios.post('/api/admin/providerauthservice/initiateAuth', {
                emailid: values.email,
            });
            if (response.status === 200) {
                this.setState({ isLoginCompleted: false });
                if (response.data.type === 2) {
                    //SAML authentication flow in progress
                    let SAMLRedirectURL = await axios.post('/api/admin/providerauthservice/samlredirecturl', {
                        emailid: values.email,
                    });
                    window.location = SAMLRedirectURL.data.redirecturl;
                }
                else {
                    if (response.data.variant === 2) {
                        this.setState({ isLoginCompleted: false });
                        try {
                            let sendOTPResponse = await axios.post('/api/admin/providerauthservice/sendotp', {
                                emailid: values.email,
                            });
                            if (sendOTPResponse.status == 200) {
                                localStorage.setItem("otpToken", sendOTPResponse.data.token);
                                this.props.handleTrustComputer(false);
                                this.props.handleEmailInput(values.email);
                                this.setState({ hasOtp: response.data.variant, renderto: redirectTo });
                            }

                        }
                        catch (err) {
                            this.setState({ renderErrorFromSendotpAPI: true })
                            if (err.response.status === 403) {
                                if (err.response.data.warningmessage === this.props.t('login.FIFTH__TIME__WRONG_ATTEMPT')) {
                                    this.setState({ isLoginCompleted: false, forceRedirect: true, renderto: '/guest/locked' });
                                }
                                let errMessage = <>{err.response.data.warningmessage}</>
                                this.setState({ isLoginCompleted: false, userError: errMessage });

                            }
                            else {
                                let errMessage = <>{this.props.t('publicJs.P__PUBLIC__SOMETHING_WENT_WRONG')}</>
                                this.setState({ isLoginCompleted: false, userError: errMessage });
                            }
                        }
                    }

                    else {
                        this.setState({ displayPasswordScreen: true });
                    }
                }
            }
        }
        else {
            try {
                let response = await axios.post('/api/admin/providerauthservice/login', {
                    username: values.email,
                    password: values.password,
                    offset: offset,
                    type: 1
                });
                if (response.status === 200) {
                    if (response.data.type === 2) {

                        this.setState({ isLoginCompleted: false });
                        localStorage.setItem("otpToken", response.data.token);
                        this.props.handleTrustComputer(true)
                        this.setState({ renderto: redirectTo, hasOtp: response.data.type });
                    }
                    else {
                        this.setState({ isLoginCompleted: false });
                        localStorage.setItem("refreshToken", response.data.refreshToken);
                        if (!response.data.userrole) {
                            let role = response.data.role;
                            this.setState({ renderto: '/firsttimelogin/' + role, forceRedirect: true });
                        } else {
                            this.setState({ renderto: loginRedirectUrl(redirectTo, response.data.userrole) });
                            this.props.currentUser.refreshTokens();
                            this.props.currentUser.changeUser(createUserObject(response.data));
                            this.setState({ loginSucess: true });
                        }
                    }
                }
            } catch (err) {
                if (err.response.status === 403) {
                    if (err.response.data.warningmessage === this.props.t('login.FIFTH__TIME__WRONG_ATTEMPT')) {
                        this.setState({ isLoginCompleted: false, forceRedirect: true, renderto: '/guest/locked' });
                    }
                    let errMessage = <>{err.response.data.warningmessage}</>
                    this.setState({ isLoginCompleted: false, userError: errMessage });

                }
                else {
                    let errMessage = <>{this.props.t('publicJs.P__PUBLIC__SOMETHING_WENT_WRONG')}</>
                    this.setState({ isLoginCompleted: false, userError: errMessage });
                }
            }
        }
    }
    handleSignup = () => {
        this.props.history.push('/signup');
    }
    handleShowPassword = () => {
        this.setState({ password: "text", showPassword: false });
    }
    handleChange = () => {
        this.setState({ renderErrorFromSendotpAPI: false }) 
    }
    handleEditEmail = (props) => {
        props.setFieldValue("password", "");
        this.setState({ userError: '' });
        this.setState({ displayPasswordScreen: false });
    }
    handleHidePassword = () => {
        this.setState({ password: "password", showPassword: true });
    }
    renderTo = (redirectTo, role) =>{
        this.setState({ renderto: loginRedirectUrl(redirectTo, role) });
        this.props.currentUser.refreshTokens();
        this.setState({ loginSucess: true });
    }
    componentDidMount = async () => {
        let { redirectTo } = queryString.parse(this.props.location.search);
        let response = await axios.get('/api/admin/providerauthservice/getdeploymentcountry', null);
        this.setState({ country: response.data });
        if (localStorage.getItem("refreshToken")) {
            let result = await axios({
                method: 'post',
                url: '/api/admin/providerauthservice/accessTokenByRefershToken',
                data: { refreshToken: localStorage.getItem("refreshToken"), offset: new Date().getTimezoneOffset() * -1 }
            });
            if (result.status === 200) {
                localStorage.setItem("refreshToken", result.data.refreshToken);                
                if (result.data.role === 1) {
                    this.renderTo(redirectTo, result.data.role)   
                }
                else if (result.data.role === 2 || result.data.role === 5) {
                    this.renderTo(redirectTo, result.data.role)
                }
                else if (result.data.role === 3) {
                    this.renderTo(redirectTo, result.data.role)
                }
                else if (result.data.role === 6) {
                    this.renderTo('/firsttimelogin', result.data.role)
                }
                else if (result.data.role === 4) {
                    this.renderTo(redirectTo, result.data.role)
                }
            }
        }
    }
    getRedirectQueryString = function (redirectTo) {
        let qs = { redirectTo };
        return `?${queryString.stringify(qs)}`;
    }
    render() {
        const { classes, t } = this.props;
        const loginCompleted = (this.props.currentUser.userDetails.isAuthenticated && this.state.loginSucess) || this.state.forceRedirect;
        return (
            <div>
                <Helmet>
                    <title>
                        {t('login.L__LOGIN_TITLE_TEXT', { '#PRODUCT_NAME': this.props.currentUser.productName })}
                    </title>
                </Helmet>
                {(this.state.forceRedirect) ? <Redirect to={this.state.renderto} /> : <>
                    {this.state.hasOtp == 2 && <Redirect to={`/guest/otp${this.getRedirectQueryString(this.state.renderto)}`} />}
                    {loginCompleted ? (
                        <Redirect to={`/accountselection${this.getRedirectQueryString(this.state.renderto)}`} />
                    ) : (                       
                        <Formik initialValues={{ email: this.props.emailInput ? this.props.emailInput : "", password: "" }}
                            onSubmit={this.handleSubmit} validationSchema={this.yupSchema}>
                            {(props) => (
                                <Form>
                                    <div>
                                        <Typography className={classes.LoginHeading} type="title">{t('common.COMMON__LOGIN')}</Typography>
                                        <Typography className={classes.LoginSubHeading} type="body1">{t('login.P_LOGIN_WELCOME_BACK_TO_MYHOPE', { '#PRODUCT_NAME': this.props.currentUser.productName })}</Typography><br /><br />
                                        <Field
                                        component={FormikTextField}
                                        variant="filled"
                                        label={t('login.L__LOGIN__EMAIL')}
                                        id="email"
                                        onChange={this.handleChange}
                                        name="email"
                                        fullWidth
                                        placeholder={t('login.L__LOGIN__EMAIL')}
                                        InputProps={{
                                            type: "email",
                                           className: classes.ieRemoveIcon,
                                           disabled:this.state.displayPasswordScreen,
                                           endAdornment: (
                                                this.state.displayPasswordScreen &&   
                                                <InputAdornment position="end">
                                                    <EditIcon onClick={() => this.handleEditEmail(props)} className={classes.icnColor} />
                                                </InputAdornment>
                                            )
                                        }}
                                        
                                        style={{ paddingBottom: 10 }}
                                    />
                                
                                        {this.state.renderErrorFromSendotpAPI && <p style={{ color: "#d51035" }} >{this.state.userError}</p>}
                                        <br />
                                        {
                                            this.state.displayPasswordScreen &&
                                            <>
                                                <Field
                                                    component={FormikTextField}
                                                    variant="filled"
                                                    label={t('login.L__LOGIN__PASSOWRD')}
                                                    id="password"
                                                    fullWidth
                                                    name="password"
                                                    placeholder={t('login.L__LOGIN__PASSOWRD')}
                                                    autoComplete="off"
                                                    InputProps={{
                                                        type: this.state.password,
                                                        className: classes.ieRemoveIcon,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {this.state.showPassword ? <VisibilityIcon onClick={this.handleShowPassword} className={classes.icnColor} /> : <VisibilityOffIcon onClick={this.handleHidePassword} className={classes.icnColor} />}
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                <div className={classes.textRight}><Link className={classes.forgotLink} to={"/guest/ForgotPassword/"}> {t('login.L__LOGIN__FORGOT')}</Link></div>
                                                <p style={{ color: "#d51035" }} >{this.state.userError}</p>

                                            </>
                                        }
                                        <CardFooter className={classes.cardFooter} disabled={props.isSubmitting} >
                                            <br /><br />
                                            <Button type="submit" primary dashboard loading={this.state.isLoginCompleted || this.state.otpSpinner} disabled={(this.state.isLoginCompleted || this.state.otpSpinner)}>
                                                {t('login.L__LOGIN__LOGIN_BUTTON')}
                                            </Button>
                                        </CardFooter>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </>
                }
                {this.state.country == 2 && <div className={classes.hippa}>
                    <p>{t('login.L__LOGIN__HIPPA_CONTENT', { '#PRODUCT_NAME': this.props.currentUser.productName })}</p>
                </div>}
            </div>
        );
    }
}

export default withTranslation()(withStyles(styles)(WithUser(LoginPage)));