import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from './CustomComponents/Button';
import { withTranslation } from 'react-i18next';
import ErrorDialog from './ErrorDialog';
import WithUser from "../ContextAndHOC/WithUser";

const styles = theme => ({
    primaryBtn: {
        color: theme.palette.text.primaryButtonTextColor,
        backgroundColor:theme.palette.primary.main,
        border:"1px solid "+theme.palette.primary.main,
        borderRadius: "50px",
        margin:"8px 0",
        textAlign:"right",
        padding: "7px 15px",
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color:theme.palette.text.primaryButtonTextColor,
          border:"1px solid "+theme.palette.primary.main,
          opacity:"0.8"
        }
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    loginButton: {
        color: theme.palette.text.primaryButtonTextColor, backgroundColor:theme.palette.primary.main, border:"1px solid "+theme.palette.primary.main, borderRadius: "50px", margin:"8px 0", textAlign:"right", padding: "7px 15px",'&:hover': { backgroundColor: theme.palette.primary.main, color:theme.palette.text.primaryButtonTextColor, border:"1px solid "+theme.palette.primary.main, opacity:"0.8"}
    },
});

function TimeOutBackground(props){
    const {classes, t}= props;
    React.useEffect(()=>{
        props.currentUser.changeUser();
    },[])
   const handleClick = () => {
         props.history.push(`/`);
    }
    let okButton = [<Button primary className={classes.loginButton} onClick={handleClick}>{t('login.L__LOGIN__LOGIN_BUTTON')}</Button>]
    let HeaderText = t('publicJs.P_PUBLIC_SESSION_HAS_BEEN_EXPIRED')
    const dismissDialog = () => {
        props.history.push(`/`);
    }
    
    return(
        <ErrorDialog successButton={okButton} HeaderText={HeaderText} dismiss={dismissDialog} />
    )
}

export default withTranslation()(withStyles(styles)(WithUser(TimeOutBackground)));