import React from 'react';
import axios from 'axios';
import queryString from 'query-string';
import Typography from '../../components/CustomComponents/Typography';
import Button from '../../components/CustomComponents/Button';
import { withStyles, InputAdornment } from "@material-ui/core";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import CardFooter from "../../components/Card/CardFooter.jsx";
import WithUser from "../../ContextAndHOC/WithUser";
import { Formik, Form, Field } from 'formik';
import { string, object, boolean, ref } from 'yup';
import FormikTextField from '../../components/FormikValidatedComponents/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { getStyleForResetPasswordScreen } from '../../components/CustomStyles/PublicPageStyles.jsx';
const styles = theme => getStyleForResetPasswordScreen(theme);

class ResetPassword extends React.Component {
  constructor(props) {
    super(props)
    const { t } = this.props;
    this.formikValidation = object().shape({
      password: string().required(t('login.L__LOGIN__LOGIN_ERROR')).min(8, t('login.L__LOGIN_FIRST_TIME_SET_PASSWORD_CONDITION')).matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$()≠!%*#_?&^+=-])[A-Za-z\d$@$()≠!%*#_?&^+=-]{8,}$/, t('common.COMMON__SET_PASSWORD_CONDITIONS')),
      cnfpassword: string().required(t('login.L__LOGIN__LOGIN_ERROR')).oneOf([ref('password')], t('common.COMMON__EQUAL_TO_PASSWORD')),
      acceptTerms: boolean().required('').oneOf([true], '')
    });

    this.state = {
      resetPassworSuccess: true,
      invalidToken: "",
      language: this.props.i18n.language,
      password: "password", showPassword: true, confPassword: "password", showCnfPassword: true
    }
  }
  
  handleChange = (name) => event => {
    this.setState({ [name]: event.target.checked, });
  };
//   handleTermCondition = async () => {
//     this.setState({ anchor: null });
//     window.open(this.props.theme.staticContent.termAndPrivacyUrl+`${this.props.i18n.language}_${this.props.TermsAndPrivacy.termsAndConditionVersion}_${this.props.TermsAndPrivacy.termsAndConditionFileName}`);
// }
  handleSubmit = async (values) => {
    const parsed = queryString.parse(this.props.location.search);

    axios.post('/api/admin/providerauthservice/resetpassword', {
      token: parsed.reset_token,
      password: values.password,
      cnfpassword: values.cnfpassword,
      acceptTerms: values.acceptTerms
    }).then(response => {
      if (response.data.status === 1) {
        this.setState({ resetPassworSuccess: false })
      } else {
        this.setState({ invalidToken: this.props.t('common.COMMON__MISSING_RESET_TOKEN') })
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  }
  //handle dismissDialog
  dismissDialog = () => {
    this.setState({ resetPassworSuccess: true });
    this.props.history.replace(`../login`)
  }
  handleShowPassword = () => {
    this.setState({ password: "text", showPassword: false })
  }
  handleHidePassword = () => {
    this.setState({ password: "password", showPassword: true })
  }
  handleShowConfPassword = () => {
    this.setState({ confPassword: "text", showCnfPassword: false })
  }
  handleHideConfPassword = () => {
    this.setState({ confPassword: "password", showCnfPassword: true })
  }
  handlePaste = (e) => {
    e.preventDefault();
}
  render() {
    const { classes, t } = this.props;
    return (
      <div>
        <Helmet>
          <title>
            {t('login.L__LOGIN_RESET_TITLE_TEXT',{'#PRODUCT_NAME':this.props.currentUser.productName})}
          </title>
        </Helmet>
        {(this.state.resetPassworSuccess ? <Formik initialValues={{ password: "", cnfpassword: "", acceptTerms: false }} onSubmit={this.handleSubmit} validationSchema={this.formikValidation}
        >
          {(props) => (
            <Form>
              <Card style={{ boxShadow: "none" , marginBottom:0}}>
                <CardBody>
                  <Typography className={classes.LoginHeading} type="title">{t('common.COMMON__SET_NEW_PASSWORD')}</Typography>
                  <br />
                  <Field
                    component={FormikTextField}
                    label={t('common.COMMON__NEW_PASSWORD')}
                    name="password"
                    onChange={this.handleChange}
                    fullWidth
                    variant="filled"
                    InputProps={{
                      type: this.state.password,
                      className: classes.ieRemoveIcon,
                      endAdornment: (
                        <InputAdornment position="end">
                          {this.state.showPassword ? <VisibilityIcon onClick={this.handleShowPassword} className={classes.icnColor} /> : 
                          <VisibilityOffIcon onClick={this.handleHidePassword} className={classes.icnColor} />}
                        </InputAdornment>
                      )
                    }}
                  />
                  <p>{t('login.P__LOGIN_RULES')} 
                    <span style={{ color: "rgba(65, 117, 5, 1)" }}>{t('login.P__LOGIN_EIGHT_CHARACTERS')}</span> | 
                    <span style={{ color: "rgba(65, 117, 5, 1)" }}>{t('login.P__LOGIN_ABC')}</span> | 
                    <span style={{ color: "rgba(65, 117, 5, 1)" }}>123</span> | 
                    <span style={{ color: "rgba(65, 117, 5, 1)" }}>@!$%^*()</span> | 
                    <span style={{ color: "rgba(65, 117, 5, 1)" }}>≠ email</span>
                  </p>
                  <br/>
                  <Field
                    component={FormikTextField}
                    label={t('common.COMMON__CONFIRM_NEW_PASSWORD')}
                    onPasteCapture={this.handlePaste}
                    fullWidth
                    name="cnfpassword"
                    onChange={this.handleChange}
                    variant="filled"
                    InputProps={{
                      type: this.state.confPassword,
                      className: classes.ieRemoveIcon,
                      endAdornment: (
                        <InputAdornment position="end">
                          {this.state.showCnfPassword ? <VisibilityIcon onClick={this.handleShowConfPassword} className={classes.icnColor} /> : <VisibilityOffIcon onClick={this.handleHideConfPassword} className={classes.icnColor} />}
                        </InputAdornment>
                      )
                    }}
                  />
                  <div className={classes.invalidtoken}>{this.state.invalidToken}</div>
                  <br/>
                  <div style={{ textAlign: "right" }}>
                    <Button type="submit" primary dashboard className={classes.primaryBtn} size="small" >
                      {t('common.COMMON__CONFIRM_BUTTON')}
                    </Button>
                  </div>
                </CardBody>
                <CardFooter className={classes.cardFooter} disabled={props.isSubmitting} >
                  <GridContainer>

                    <br />
                    <GridItem xs={12} sm={12} md={12} lg={12} style={{ paddingLeft: "0" }}>
                      <FontAwesomeIcon icon={faChevronLeft} className={classes.backLogin}/>&nbsp; 
                      <Link className={classes.backLogin} to={"/guest/Login/"}>{t('common.COMMON__BACK_TO_LOGIN')}</Link>
                    </GridItem>
                  </GridContainer>

                </CardFooter>
              </Card>
            </Form>
          )}
        </Formik> :
          <Card style={{ boxShadow: "none" }}>
            <CardBody>
              <Typography className={classes.LoginHeading} type="title">{t('login.P__LOGIN_SUCCESS')}</Typography><br />
              <Typography variant="h4" color="inherit" className={classes.loginSuccess} type="body1">{t('common.COMMON__PASSWORD_CHANGED_SUCCESSFULLY',{'#PRODUCT_NAME':this.props.currentUser.productName})}</Typography>
              {/* <Typography variant="h4" color="inherit" className={classes.loginSuccess}>{t('common.COMMON__LOGIN_WITH_NEW_PASSWORD')}</Typography> */}
            </CardBody>
            <br />
            <CardFooter className={classes.cardFooter} >
              <Button className={classes.primaryBtn} primary dashboard size="small" onClick={this.dismissDialog}>
                {t('login.L__LOGIN__CONTINUE_BUTTON')}
              </Button>
            </CardFooter>  
          </Card>
          )}
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true })(WithUser(ResetPassword)));
