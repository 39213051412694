import React, { useEffect } from "react";
import axios from 'axios';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import { loginRedirectUrl } from './CommonFunction';
import CircularProgress from '@material-ui/core/CircularProgress';


function ProcessSSO(props){

    const getRedirectQueryString = function (redirectTo) {
        let qs = { redirectTo };
        return `?${queryString.stringify(qs)}`;
    }
    const fetchRefreshToken = async ()=>{
        try {
            let { token } = queryString.parse(props.location.search);
            let refreshToken = await axios.post(`/api/admin/providerauthservice/processSSO/${token}`,null) 
            if(refreshToken.status == 200){
                localStorage.setItem("refreshToken",refreshToken.data.refreshToken)
                window.open(`/accountselection${getRedirectQueryString(loginRedirectUrl("",refreshToken.data.userRole))}`,"_self")
            }
        } catch (error) {
            console.log(error)
            if(error.response){
                if(error.response.status == 422){
                    props.history.push(`/guest/errorpage?errorMessage=${error.response.data.error.details}`);    
                }else{
                    props.history.push(`/guest/errorpage?errorMessage=${error.response.data.statusDescription}`);
                }
            }
            else{
                props.history.push(`/guest/errorpage?errorMessage=${error}`);
            }
        }

    }
    useEffect(()=>{
        fetchRefreshToken()
    })
    return(
        <div><CircularProgress color="primary" style={{ position: "absolute", top: "50%", left: "50%" }} /></div>
    )
}
export default withTranslation()(ProcessSSO)