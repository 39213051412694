import React from 'react';
import MUIDataTable from "mui-datatables";
import { withTranslation } from 'react-i18next';
import withWidth from '@material-ui/core/withWidth';
import noPatientImg from '../assets/images/noPatientImg.png';
import GridContainer from './Grid/GridContainer'
import GridItem from './Grid/GridItem';
import Button from './CustomComponents/Button';
import { NavLink } from 'react-router-dom';
import withUser from "../ContextAndHOC/WithUser";
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    patientDashBoardText : {textAlign:"center",maxWidth: "416px",margin:"10px auto",fontSize:17,color:theme.palette.primary.main,fontWeight:"600"},
    dashBoardButton : {background: "transparent",border: "2px solid "+theme.palette.primary.main,color: theme.palette.primary.main,borderRadius: "25px",padding: "6px 45px",textDecoration:"none",fontSize:"16px !important"}
});
class MuiThemeDataTable extends React.PureComponent {

            options = {
            expandableRows: this.props.expandableRows,
            renderExpandableRow: (rowData, rowMeta) => this.props.renderExpandableRow(rowData, rowMeta),
            filterType: 'checkbox',
            selectableRows: false,
            download: this.props.hideDownloadIcon,
            search: this.props.hideSearchIcon,
            filter: this.props.hideFilterIcon,
            elevation: 0,
            viewColumns: this.props.viewColumnsIcon,
            rowHover: false,
            pagination: this.props.pagination!==false?true:false,
            rowsPerPageOptions: [10, 25, 50],
            responsive: "scroll",
            print: false,
            customSort : this.props.customSort,
            textLabels: {
                body: {
                    noMatch: this.props.t('common.COMMON__NO_DATA'),
                    toolTip:""
                },
                pagination: {
                    rowsPerPage: this.props.t('common.COMMON__ROW_PER_PAGE'),
                    displayRows: this.props.t('common.COMMON__PAGINATION_OF')
                 },
                toolbar: {
                    search: this.props.t('common.COMMON__SEARCH'),
                    downloadCsv: this.props.t('dashboard.DASHBOARD__DATA_TABLE_DOWNLOAD'),
                    viewColumns: this.props.t('dashboard.DASHBOARD__DATA__TABLE_VIEW_COLUMNS'),
                    filterTable: this.props.t('dashboard.DASHBOARD__DATA__TABLE_FILTER_TABLE')
                  },   
                filter: {
                    title: this.props.t('dashboard.DASHBOARD__DATA__TABLE_FILTERS'),
                    reset: this.props.t('dashboard.DASHBOARD__DATA__TABLE_RESET'),          
                  },
                  viewColumns: {
                    title: this.props.t('dashboard.DASHBOARD__DATA__TABLE_SHOW_COLUMN')
                  }
            }
            }
        render() {
            let columns = [];
            // if((this.props.width === "sm" || this.props.width === "xs") && this.props.tableContent === "patientList" ){
            //     columns.push(this.props.columns[1]);
            //     columns.push(this.props.columns[0]);
            //     this.options.onRowClick = (rowData, rowMeta) => {
            //         this.props.rowDetailsRedirectFunction(rowData, rowMeta)
            //     }
            // }
            // else if(this.props.width === "sm" || this.props.width === "xs"){
            //     this.props.smallScreenTableColumnDisplayIndex.forEach(element => {
            //         columns.push(this.props.columns[element])
            //     });
            //     this.options.onRowClick = (rowData, rowMeta) => {
            //     }
            // }
            // else {
                this.options.expandableRows = false;
                columns = this.props.columns
                this.options.onRowClick = (rowData, rowMeta) => {
                }
            // }
        return (
            ((this.props.rows.length>=0 && this.props.patientsEmpty!==true)||(this.props.rows.length>0 && this.props.patientsEmpty===true))?
            <MUIDataTable
            key={columns.length} 
            title={this.props.title}
            data={this.props.rows}
            columns={columns}
            options= {this.options}
            className="printStyle"
        />:<><MUIDataTable key={columns.length} title={this.props.title} columns={columns} options= {this.options} className="hidetableBody"/><div style={{background: "#fff",height: "75vh",display: "flex",alignItems: "center",justifyContent: "center"}}><GridContainer><GridItem style={{textAlign:"center"}}><img src={noPatientImg} width="150px" /></GridItem><GridItem><h5 className={this.props.classes.patientDashBoardText}>{this.props.t('dashboard.DASHBOARD_NO_PATIENTS',{'#PRODUCT_NAME':this.props.currentUser.productName})}</h5></GridItem><GridItem style={{textAlign:"center"}}><br/>{(this.props.userRole == 'AccountAdmin' || this.props.userRole == 'DefaultAccountAdmin')&&<Button onClick={this.props.handleAddNewPatient} className={this.props.classes.dashBoardButton+" noPatientBtn"} to={'createUser'} >{this.props.t('prescribe.P__PRESCRIBE_CM_RX')}</Button>}</GridItem></GridContainer></div>
        </>)
    }
}
export default withWidth()(withTranslation()(withStyles(styles)(withUser(MuiThemeDataTable))));