import { createTheme } from "@material-ui/core/styles";


export default function newTheme(theme) {
  let customObject = {
    custom: {
      loginBgImage: "bg.png",
      loginLogo: theme.logo?theme.logo:"myHope.png",
      navbarImage: theme.logo?theme.logo:"/images/myHope.png",
      navbarImageBg:theme.logo?theme.logo:"/images/myHope.png",
      navbarImageMobile: theme.logo?theme.logo:"/images/myHope.png",
      activeNavLink:theme.titleColor,
      colorWhite:"#fff",
      buttonDisabledBg: "rgba(243, 244, 245, 1)",
      buttonDisabledTextClr:"rgba(83, 108, 112, 0.54)",
      buttonDisabledBdrClr:"rgba(243, 244, 245, 1)"
    },

    palette: {
      common: {
        black: "#000",
        white: "#fff"
      },
      background: {
        paper: "#fff",
        default: "#fff"
      },
      secondary: {
        main: theme.secondaryColor
      },
      primary: {
        // light : "#fff",
        dark: "",
        main: theme.primaryColor
      },
      // thirdColor: {
      //   main: theme.tertiaryColor
      // },
      border: {
        primaryBorder: theme.primaryColor,
        secondaryBorder: theme.secondary,
        thirdBorder: theme.tertiaryColor,
      },
      error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#fff"
      },
      text: {
        textPrimaryColor: theme.primaryTextColor,
        textSecondaryColor: theme.secondaryTextColor,
        textThirdColor: theme.tertiaryTextColor,
        textFourthColor:"#737373",
        primaryButtonTextColor: theme.primaryButtonTextColor,
        // secondaryButtonTextColor: theme.secondaryButtonTextColor,
        titleColor:theme.titleColor,
        primary: "#515974",
        secondary: "#515974",
        third: "#fff",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
      },
      hoverPrimaryColor: {
        main: theme.primary
      },
      hoverSecondaryColor: {
        main: theme.secondary
      },
      hoverThirdColor: {
        main: "#fff"
      },
      minWidthMenu:{
        minWidthMenu: 155
      },
      logo: {
        logoSize: 150
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily: "'Roboto', 'Helvicta','Arial',sans-serif",
      button: {
        textTransform: "inherit"
      }
    },
    overrides: {
      MUIDataTableFilter:{
        checkboxFormControlLabel:{
          marginLeft: "0px",
          marginRight: "15px"
        },
        resetLink:{
                marginLeft: "0px"
            },
            root:{
              paddingLeft: "10px",
              paddingTop: "15px"
            }
      },
      MUIDataTablePagination:{
        root:{
          borderBottom:"none !important"
        }
      },
      MuiFilledInput:{
        root:{
          backgroundColor: "#f4f5f5",
          '&:hover':{
            backgroundColor: "#f4f5f5",
          }
        }
      },
      MUIDataTable:
      {
        responsiveScroll: {
          maxHeight: "100% !important",
          minHeight: 300
        }
      },
      MuiTableFooter:{
        root:{
          background:"#f8f8f8 !important"
        }
      },
      MUIDataTableHeadCell: {
        root: {
          color: theme.primaryTextColor,
          fontWeight: "bolder",
          background:"#f9fafa !important",
          padding: "20px 25px 19.5px 28px"
        },
        sortActive:{
          color: theme.primaryTextColor
        }
      },
      MUIDataTableToolbar:{
        root:{
            color: "#ff0000",
        },
        titleText:{
          color : "#ff0000"
        }
      },
      PrivateNotchedOutline:{
        legend:{
          width:"inherit !important"
        }
      },
      MuiButtonBase: {
        root: {
          whiteSpace: "nowrap"
        }
      },
      MuiTableCell:
      {
        root:
        {
          "padding": "4px 25px 4px 28px"
        },
      },
      
      MuiInputBase:{
        input:{
          height:"inherit",
          padding: "10px 0 7px",
          color: theme.tertiaryTextColor,
          '&:-webkit-autofill,-webkit-autofill:hover,-webkit-autofill:focus':{
            "-webkit-text-fill-color": theme.tertiaryTextColor,
            "-webkit-box-shadow": "0 0 0px 1000px #f4f5f5 inset",
            transition: "background-color 5000s ease-in-out 0s",
          },
          '&:-webkit-autofill:disabled':{
          "-webkit-text-fill-color": '#535b76',
          "-webkit-box-shadow": "0 0 0px 1000px #e0e0e0 inset",
          transition: "background-color 5000s ease-in-out 0s",
        }
        }
      },
      MuiGridListTile:{
        root:{
          width:"100% !important",
          height:"inherit !important"
        }
      },
      MuiFormGroup:{
        root:{
          display:"inherit"
        }
      },
      
      MuiStepConnector:{
        active :{
          background: theme.primaryColor,
          width: "3.3px",
          marginLeft: 11
        },
        completed :{
          background: theme.primaryColor,
          width: "3.3px !important",
          height: 50,
          borderLeftWidth: "3.3px !important"
        },
        vertical:{
          padding:"0"
        },
        lineVertical:{
          borderColor: "#429ed5",
          minHeight:50
        }
      },
      MuiStepIcon:{
        text:{
          fill:"#fff"
        }
      },
      MuiStepLabel:{
        label:{
          paddingLeft: 20
        },
        iconContainer:{
          paddingRight:"0px"
        },
        active: {
          color: theme.primaryColor+" !important"
        },
        completed:{
          color:"rgba(218, 223, 224, 1) !important"
        }
      },
      MuiOutlinedInput:{
        input:{
          padding: "7px 8px"
        }
      },
      MuiPaper:{
        root:{
          overflow:"visible !important",
        }
      },
      MuiFormHelperText:{
        root:{
          minHeight: 0
        }
      },
      MuiDialog:{
        paperScrollPaper:{
          maxHeight:"inherit !important"
        },
        paperFullWidth:{
            width:"100% !important",
            margin: "0px !important"
        },
        root:{
          padding:"16px 16px 45px !important"
        }
      },
      MuiDialogContent:{
        root:{
          padding:0
        }
      }
    }
  }
  return createTheme(customObject)
}