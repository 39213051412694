let getCommonPublicPageStyle = (theme) => {
    return {
        primaryBtn: {
            width : "100%"
        },
        LoginHeading: { fontSize: "30px !important", fontWeight: "600 !important"},
        icnColor: { cursor: "pointer" },
        cardFooter: { justifyContent: "flex-end", padding: "16px 0px", margin: "0px 0 0px" },
        ieRemoveIcon: { '& input::-ms-clear': { 'display': 'none' }, '& input::-ms-reveal': { 'display': 'none' }, },
        LoginSubHeading: { fontSize: "18px !important", textAlign: "left", lineHeight: "24px  !important" },
        loginSuccess: { fontSize: "18px", fontWeight: "400", marginBottom: "15px" },
        refElement: { border: "1px solid "+theme.palette.text.textThirdColor, padding: " 0 3px",fontWeight: 'bold' },
        logoSize: { width: theme.palette.logo.logoSize },
        padTop: { padding: "0 40px", paddingTop: "40%", color: "#fff" },
        clrWhite: { color: "#fff !important" },
        fntSze20: { fontSize: "20px !important" },
        fntSze19: { fontSize: "19px !important" }
    }
}

const getStyleForTNCUpdateChecker = (theme) => {
    let commonStyleObject = getCommonPublicPageStyle(theme);
    commonStyleObject.primaryBtn.borderRadius = "50px";
    commonStyleObject.primaryBtn.margin = "8px 0";
    commonStyleObject.primaryBtn.textAlign = "right";
    commonStyleObject.primaryBtn.padding = "7px 35px";
    commonStyleObject.TermsCondition = { color: theme.palette.primary.main, padding: 0, textDecoration: "underline", backgroundColor: "transparent", border: "none", textAlign: "left", cursor: "pointer", fontSize: "16px", "&:hover": { background: "transparent", textDecoration: "underline" } };
    commonStyleObject.tncDialogTitle = { fontSize: "17px", fontWeight: "600", textAlign: "center", marginBottom: "12px" };
    commonStyleObject.tncDialogBody = { maxWidth: "438px", margin: "0 auto" };
    commonStyleObject.mainDialog = { maxWidth: 766 };
    commonStyleObject.textCenter = { textAlign: "center", marginTop: "15px" };
    return commonStyleObject;
}
const getStyleForForgotPassword = (theme) => {
    let commonStyleObject = getCommonPublicPageStyle(theme);
    commonStyleObject.primaryBtn.width = "100%";
    commonStyleObject.primaryBtn.borderRadius = "50px";
    commonStyleObject.primaryBtn.margin = "8px 0";
    commonStyleObject.primaryBtn.textAlign = "right";
    commonStyleObject.primaryBtn.padding = "6px 17px";
    commonStyleObject.rememberMe = { fontSize: "16px !important", textAlign: "center", fontWeight: 450 };
    commonStyleObject.font15 = { paddingRight: "15px", fontSize: "15px !important", paddingTop: 16 };
    commonStyleObject.font18 = { color: theme.palette.primary.main, fontSize: "18px !important" };
    commonStyleObject.actionBtn ={
        width:"100%"
    }
    commonStyleObject.backLogin = {color:theme.palette.primary.main};
    commonStyleObject.cardFooter = { paddingLeft: 15 };
    commonStyleObject.invalidUser = { color: 'red' };
    commonStyleObject.padTop0 = { padding: 0 };
    commonStyleObject.fontSize16 = { fontSize: "18px !important",margin:'18px 0'  };
    commonStyleObject.root = { width: 322 };
    commonStyleObject.passwordTitle = { fontSize: "31px !important", fontWeight: "600 !important", whiteSpace: 'nowrap' };
    commonStyleObject.backLoginLink = {textDecoration: "none", color:theme.palette.primary.main}
    return commonStyleObject;
}
const getStyleForPublicPage = (theme) => {
    let commonStyleObject = getCommonPublicPageStyle(theme);
    commonStyleObject.logoSize.margin = "10px"
    commonStyleObject.login = { width: "360px", paddingTop: "25%", position: "relative", minHeight: "95vh" };
    commonStyleObject.footerWrap = { maxWidth: "360px", textAlign: "center", position: "sticky", bottom: 0, background: "#fff", padding: "10px 0" };
    return commonStyleObject;
}
const getStyleForFirstTimeLoginScreen = (theme) => {
    let commonStyleObject = getCommonPublicPageStyle(theme);
    commonStyleObject.primaryBtn.width = "50%";
    commonStyleObject.primaryBtn.borderRadius = "25px";
    commonStyleObject.primaryBtn.padding = "10px";
    commonStyleObject.primaryBtn.marginTop = "-35px";
    commonStyleObject.errorBtn = {borderRadius : "50px",margin : "8px 0",textAlign : "right",padding : "6px 17px",
        fontWeight : "400",lineHeight : "1.42857143"};
    commonStyleObject.TermsCondition = { color: theme.palette.primary.main, textDecoration: "underline", backgroundColor: "transparent", border: "none", textAlign: "left", cursor: "pointer", fontSize: "16px" };
    commonStyleObject.fntSz_16 = { paddingLeft: 6};
    commonStyleObject.login = { width: "360px", paddingTop: "30%", marginBottom: '57px' };
    commonStyleObject.LoginHeading.paddingBottom = 16;
    commonStyleObject.cardFooter = { justifyContent: "flex-end", padding: 0, margin: "30px 0 20px" };
    commonStyleObject.marginBottom40px = { marginBottom: '40px' };
    commonStyleObject.checkBox = { paddingLeft: 6, color: theme.palette.text.textPrimaryColor };
    commonStyleObject.ieRemoveIcon = { '& input::-ms-clear': { 'display': 'none' }, '& input::-ms-reveal': { 'display': 'none' } };
    return commonStyleObject;
}
const getStyleForFirstScreen = (theme) => {
    let commonStyleObject = getCommonPublicPageStyle(theme);
    commonStyleObject.stepperMargin = { margin: "125px auto 20px", [theme.breakpoints.down('sm')]: { margin: 0 } };
    commonStyleObject.continueButton = { textAlign: "center", marginTop: '10px' };
    commonStyleObject.versionWrap = { display: "flex", alignItems: "baseline", maxWidth: 332, margin: "0 auto" };
    commonStyleObject.warningWrap = { display: "flex", maxWidth: 332, margin: "0 auto" };
    commonStyleObject.versionText = { fontSize: "18px !important", fontWeight: 500, marginLeft: 16 };
    commonStyleObject.refElement.fontSize = "12px !important";
    commonStyleObject.primaryBtn.textTransform = "capitalize";
    commonStyleObject.primaryBtn.borderRadius = "50px";
    commonStyleObject.primaryBtn.textAlign = "right";
    commonStyleObject.primaryBtn.padding = "7px 65px";
    commonStyleObject.robotoTxt = { fontFamily: "'Roboto', sans-serif;", margin: "40px 0" };
    commonStyleObject.root = { backgroundColor: "#fff", height: "90vh" };
    commonStyleObject.vCenter = { position: "relative" };
    commonStyleObject.versionNumber = { fontWeight: 400 };
    commonStyleObject.cautionTxt = { fontWeight: 400 };
    commonStyleObject.warningTxt = { fontSize: "18px !important", fontWeight: 500, lineHeight: 1 };
    commonStyleObject.body2 = { marginLeft: 25 };
    return commonStyleObject;
}
const getStyleForAboutPage = (theme) => {
    let commonStyleObject = getCommonPublicPageStyle(theme);
    commonStyleObject.head = { textAlign: "center",  fontSize: 24, paddingBottom: 8, fontWeight: 'normal' };
    commonStyleObject.body1 = { textAlign: "center", fontSize: 16, maxWidth: 262, margin: "0 auto", paddingBottom: 30, fontWeight: 'normal' };
    commonStyleObject.vCenter = { alignItems: "center", margin: "15px auto 0", maxWidth: "445px" };
    commonStyleObject.versionNum = { fontSize: "16px !important" };
    commonStyleObject.versionTit = { fontSize: "16px !important",};
    commonStyleObject.refElement.fontSize = "9px !important";
    commonStyleObject.root = { padding: 30, margin: "0 auto", backgroundColor: "#f3f4f5", [theme.breakpoints.down('md')]: { paddingRight: "15px", paddingLeft: "15px" } };
    commonStyleObject.industryIcon = { width: "23px" };
    commonStyleObject.twoColumnSectionMxWidth = { maxWidth: 560, margin: "0 auto" };
    commonStyleObject.leftBrand = { display: "flex" };
    commonStyleObject.rightBrandTit = { marginLeft: "10px" };
    commonStyleObject.userGuideTit = { color: "#91083C", fontSize: "16px !important", lineHeight: 1, cursor: "pointer" };
    commonStyleObject.mgBtm = { marginBottom: 20 };
    commonStyleObject.body3Tit = { fontSize: "16px !important", color: theme.palette.primary.main };
    commonStyleObject.lastSectionRowMxWidth = { maxWidth: "500px", margin: "0 auto" };
    commonStyleObject.body3Txt = { fontSize: "16px !important" };
    commonStyleObject.loadDivHeight = { height: "75vh" };
    commonStyleObject.loadingStyle = { position: "relative", left: "50%", top: "50%" };
    commonStyleObject.visibilityHidden = { visibility: "hidden" };
    commonStyleObject.mgBtm8 = { marginBottom: 8 };
    return commonStyleObject;
};
const getStyleForResetPasswordScreen = (theme) => {
    let commonStyleObject = getCommonPublicPageStyle(theme);
    commonStyleObject.primaryBtn.width = "50%";
    commonStyleObject.primaryBtn.borderRadius = "25px";
    commonStyleObject.primaryBtn.padding = "10px";
    commonStyleObject.invalidtoken = { color: 'red' };
    commonStyleObject.cardFooter.padding = 0;
    commonStyleObject.cardFooter.margin = "30px 0 20px";
    commonStyleObject.backLogin = {color:theme.palette.primary.main};
    return commonStyleObject;
};
const getStyleForLockedScreen = (theme) => {
    let commonStyleObject = getCommonPublicPageStyle(theme);
    commonStyleObject.maxWidth = { maxWidth: "300px", margin: "0 auto" };
    commonStyleObject.lockdTit = { fontSize: "30px !important", fontWeight: 800 };
    commonStyleObject.lockdBdy = { fontSize: "18px !important" };
    commonStyleObject.lockdfoot = { fontSize: "18px !important", color: "rgba(109, 111, 123, 1)" };
    commonStyleObject.resentLink = {color:theme.palette.primary.main}
    return commonStyleObject;
};
const getStyleForLoginScreen = (theme) => {
    let commonStyleObject = getCommonPublicPageStyle(theme);
    commonStyleObject.primaryBtn.borderRadius = "25px";
    commonStyleObject.primaryBtn.padding = "8px";
    commonStyleObject.primaryBtn['&:disabled'] = { color: "rgb(175 175 175)", borderColor: "rgb(228 228 228)", backgroundColor: "rgb(128 126 126 / 13%)" };
    commonStyleObject.cardFooter.margin = "30px 0 20px";
    commonStyleObject.cardFooter.position = "relative";
    commonStyleObject.hippa = { border: "1px solid #429ed5", backgroundColor: "#429ed51a", borderRadius: 4, padding: "16px 16px", color: "#429ed5" };
    commonStyleObject.LoginHeading.marginBottom = 15;
    commonStyleObject.forgotLink = {textDecoration: "none", color: theme.custom.activeNavLink};
    commonStyleObject.textRight = { textAlign:"right" };
    commonStyleObject.textLeft = { textAlign:"left" };
    return commonStyleObject;
}
const getStyleForOTPScreen = (theme) => {
    let commonStyleObject = getCommonPublicPageStyle(theme);
    commonStyleObject.msePointer = {cursor:"pointer"};
    commonStyleObject.primaryBtn.borderRadius = "25px";
    commonStyleObject.primaryBtn.padding = "8px";
    commonStyleObject.fifteen = { fontSize: '15px !important', color: '#6d6f7b' };
    commonStyleObject.LoginHeading.marginBottom = 15;
    commonStyleObject.LoginHeading.whiteSpace = 'nowrap';
    commonStyleObject.email_miss = { fontSize: "14px !important", textAlign: "left", lineHeight: "24px  !important" };
    commonStyleObject.rectangle = { width: "328px", height: "84px", borderRadius: "6px", backgroundColor: "#b10222", marginBottom: '20px' };
    commonStyleObject.errorText = { width: "292px", height: "48px", fontFamily: "SFProDisplay", fontSize: "14px", fontWeight: "500", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "center", color: "#ffffff", padding: '18px' };
    return commonStyleObject;
}
const getStyleForAccountSelection = (theme) => {
    let commonStyleObject = getCommonPublicPageStyle(theme);
    commonStyleObject.root = { paddingTop: "4%", margin: "0 auto", backgroundColor: "#f3f4f5", [theme.breakpoints.down('md')]: { paddingRight: "15px", paddingLeft: "15px" } };
    commonStyleObject.displayFlx={display: "flex" }
    commonStyleObject.loadingBar = {position:"absolute",top:"30%",left:"40%"}
    commonStyleObject.submitBtn = { textTransform: "uppercase", textDecoration: "none", color: theme.palette.text.primaryButtonTextColor, borderRadius: "50px", background:theme.palette.primary.main, padding: "5px 16px", display: "inline-block","& span":{ fontSize:"12px !important"}, '&:hover': { backgroundColor: theme.palette.primary.main, color: theme.palette.text.primaryButtonTextColor } };    
    commonStyleObject.nameDiv= { margin: "20px 0", minWidth: 142 };
    commonStyleObject.root= { display: 'flex', flexWrap: 'wrap', transformOrigin: 'center bottom' };
    commonStyleObject.cardRoot= { "overflow": "initial", maxWidth: '1000px', margin: '30px auto', padding: "25px" };
    commonStyleObject.headingFont = {fontSize:"24px !important", paddingBottom:"20px"}
    commonStyleObject.labelFont = {fontSize:"24px !important"}
    commonStyleObject.elevationPopover = {zIndex:"10002 !important"}
    commonStyleObject.textCenter = {textAlign:"center"}
    commonStyleObject.navMenuWrap= { top: "58px !important", borderRadius: 0, boxShadow: "0px -2px 1px -1px rgba(0, 0, 0, 0.04), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)", '& .MuiList-padding li':{color:theme.palette.text.textThirdColor} };
    commonStyleObject.settingLink= { float: "right", color: "#001C61", margin: "0 10px", '&:hover': { background: "transparent" } }
    return commonStyleObject;
}

const samlStyle = (theme)=>{
    let commonStyleObject ={
        divider : {
            margin: "12px 0"
        },
        title:{
            fontSize: "24px !important",
            textAlign: "center",
            marginBottom : 20
        },
        textSize:{
            fontSize: "17px !important",
        },
        productItem:{
            borderBottom : "1px solid rgba(0, 0, 0, 0.08)",
            padding : "12px 20px",
            cursor:"pointer"
        },
        imageWeidth:{
            maxWidth:"120px",
            margin:"0 auto"
        },
        wrap :{ 
            minHeight: "100vh",
            position: "relative"
          },
        wrapItem: {
            margin: 0,
            position: "absolute",
            top: "50%",
            left: "50%",
            "-ms-transform": "translate(-50%, -60%)",
            transform: "translate(-50%, -60%)",
            maxWidth:"450px",
            minWidth:"450px",
            minHeight:"450px"
          },
          radioItems:{
            padding : "12px 25px",
          },
          radioLabel:{
            '& .MuiFormControlLabel-label':{
                fontSize : "17px !important"
            },
          },
          submitButton:{
            position: "absolute",
            bottom: "12%",
            left: "25%",
            margin:0
          }
    } 
    return commonStyleObject
}
export {samlStyle, getStyleForAccountSelection, getStyleForResetPasswordScreen, getStyleForTNCUpdateChecker, getStyleForForgotPassword, getStyleForPublicPage, getStyleForFirstTimeLoginScreen, getStyleForFirstScreen, getStyleForAboutPage, getCommonPublicPageStyle, getStyleForLockedScreen, getStyleForOTPScreen, getStyleForLoginScreen };
