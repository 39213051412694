import React, { useEffect,useState } from "react";
import {withStyles,withWidth} from "@material-ui/core";
import { Helmet } from "react-helmet";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import CardFooter from "../../components/Card/CardFooter.jsx";
import Button from '../../components/CustomComponents/Button';
import queryString from 'query-string';
import Typography from '../../components/CustomComponents/Typography';
import { withTranslation } from "react-i18next";
import { getStyleForLoginScreen } from '../../components/CustomStyles/PublicPageStyles.jsx';
const styles = theme => (getStyleForLoginScreen(theme));


function ErrorPage(props){
    const {classes,t}=props;
    const [errorMessage,setErrorMessage]=useState(null);

    useEffect(() => {
        let { errorMessage } = queryString.parse(props.location.search);
        setErrorMessage(errorMessage)
    })
    const dismissDialog = () => {
        props.history.push(`../login`)
    }
    return (
        <div >
            <Helmet> 
                <title>
                    {t('login.L__LOGIN_ERROR_TITLE_TEXT')}
                </title>
             </Helmet>
            <Card style={{ boxShadow: "none", width:343 }}>
                <CardBody>
                    <Typography className={classes.LoginHeading} type="title">{t('login.ERROR_MESSAGE')}</Typography><br />
                    <Typography style={{ color: "red", paddingLeft: 10 }}>{errorMessage}</Typography>
                </CardBody>
                <CardFooter className={classes.cardFooter} disabled={props.isSubmitting} >
                    <br /><br />
                    <Button primary dashboard onClick={dismissDialog}>
                        {t('login.L__LOGIN__LOGIN_BUTTON')}
                    </Button>
                </CardFooter>
            </Card> 
        </div>
    )
}

export default withTranslation()(withWidth()(withStyles(styles, { withTheme: true })(ErrorPage)));
