import React from 'react';
import { withStyles } from '@material-ui/core';
import Button from '../../components/CustomComponents/Button';
import { withTranslation } from 'react-i18next';
import { getStyleForAccountSelection } from '../../components/CustomStyles/PublicPageStyles.jsx';
const styles = theme => getStyleForAccountSelection(theme);

class ActionButtonAccountSeelction extends React.Component {
    handleManageAccount = () => {
        this.setState({ anchorEl: null });
        if(this.props.status === 1){
            this.props.handleAccountSelection(this.props.accountId);
        } else if(this.props.status === 0){
            this.props.handleAcceptInvitation(this.props.accountId);
        }
    }
    render() {
        const { classes, t } = this.props;
        return (
            <div className={classes.textCenter}>
                <Button secondary className={classes.submitBtn} onClick={this.handleManageAccount}>{this.props.status === 1?t("superAdmin.SA__SUPER__ADMIN_MANAGE_ACCOUNT"):t("superAdmin.SA__SUPER__ADMIN_ACCPT_INVITATION")}</Button>
            </div>
        )
    }
}

export default withTranslation()(withStyles(styles)(ActionButtonAccountSeelction));