import React from 'react';
import AuthenticatedPage from "./ContextAndHOC/AuthenticatedPage";
import FirstScreen from './views/Public/FirstScreen';
import { Switch } from "react-router-dom";
import Spinner from '@material-ui/core/CircularProgress';

const SuperAdmin = React.lazy(() => import(/*webpackChunkName: 'SuperAdmin' */ './views/SuperAdmin/SuperAdmin'));
const ProviderAndCoWorker = React.lazy(() => import(/*webpackChunkName: 'Provider' */ './views/Provider/ProviderAndCoWorker.jsx'));
const AccountAdmin = React.lazy(() => import(/*webpackChunkName: 'AccountAdmin' */ './views/AccountAdmin/AccountAdmin'));
const FirstTimeLogin = React.lazy(() => import(/*webpackChunkName: 'CoWorker' */ './views/Public/FirstTimeLogin'));

class ThemeHandler extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            _config: '',
            isLoading: true
        }
    }
    componentDidMount = async () => {
        let response = await this.props.authenticatedApiCall('get', `/api/admin/admin/getAccountConfig?accountId=${this.props.match.params.selectedAccountId}`, null);
        if (response.data != null) {
            let data = response.data;
            let newTheme = data.portalThemeStyle;
            localStorage.setItem("productId", data.productId);
            localStorage.setItem("isStudy", data.isStudy);
            this.props.currentUser.setTheme(newTheme, data);
            this.setState({ isLoading: false });
        }
    }
    render() {
        return (
            <>
                <Switch>
                    {this.props.location.pathname.includes('/superadmin') ? <SuperAdmin /> : ""}
                    {this.props.location.pathname.includes('/admin') ? <AccountAdmin /> : ""}
                    {this.props.location.pathname.includes('/provider') ? <ProviderAndCoWorker /> : ""}
                    {this.props.location.pathname.includes('/coworker') ? <ProviderAndCoWorker /> : ""}
                    {this.props.location.pathname.includes('/firstscreen') ? this.state.isLoading ? <Spinner style={{ position: "absolute", top: "50%", left: "50%" }} /> : <FirstScreen /> : ""}
                    {this.props.location.pathname.includes('/firsttimelogin') ? <FirstTimeLogin role={this.props.role} logout={this.props.logout} /> : ""}
                </Switch>
            </>
        )
    }
}

export default AuthenticatedPage()(ThemeHandler);