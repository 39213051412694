import React from 'react';
import { withWidth } from "@material-ui/core";
import { Offline} from "react-detect-offline";
import ErrorDialog from '../src/components/ErrorDialog';
import App from "./App";
import Button from '@material-ui/core/Button';
class RenderApp extends React.Component {

    reloadFunction = function(){
        window.location.reload();
    };

    retryButton = [<Button onClick={this.reloadFunction}>Retry</Button>];


    render(){
        return(
            <>
            <App _config={this.props.configObject} />
            <Offline polling={{url:"https://internet.code.amalgamrx.com",interval:30000,timeout:10000}}>
                <ErrorDialog  successButton={""} HeaderText={this.props.configObject.connectionMessage} dismiss={""} />
            </Offline>
        </>
        );
    }
}

export default withWidth()(RenderApp);