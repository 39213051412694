import React from 'react';
import Typography from '../components/CustomComponents/Typography';
import { withStyles, Divider, Dialog, DialogActions, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const CustomDialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
        width: "600px",
        [theme.breakpoints.down('sm')]:{
            width: "300px"
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(.0001),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },

}))(props => {
    const { children, classes, onClose } = props;
    return (
        <DialogTitle disableTypography={true} className={classes.root}>
            <Typography variant="subtitle2" type="body2" style={{width:"96%"}}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});

class SuccessDialog extends React.Component {
    state = {
        scroll: 'body'
    };

    render() {
        return (
            <Dialog
                onClose={this.props.dismiss}
                scroll={this.state.scroll}
                open={true}
                aria-labelledby="customized-dialog-title"
                disableBackdropClick
                maxWidth='sm'
                className="mainRoot"
            >
                <CustomDialogTitle id="customized-dialog-title" onClose={this.props.dismiss}>
                {this.props.HeaderText}
                </CustomDialogTitle>
                <Divider/>
                <DialogActions>
                {this.props.successButton && this.props.successButton.map((button,i)=>
                  <div key={i}><div>{button}</div></div>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

export default SuccessDialog;