import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    title:{
        color: theme.palette.text.titleColor+" !important"
    },
    body1:{
        color: theme.palette.text.textPrimaryColor+" !important"
    },
    body2:{
        color: theme.palette.text.textSecondaryColor+" !important"
    },
    body3:{
        color: theme.palette.text.textThirdColor+" !important"
    }
}));

export default function ({children,type,className,...otherProps }) {
    const classes = useStyles();
    return (
        <>
            <Typography {...otherProps}
            className = {className+" "+classes[type]}>
            {children}</Typography>
        </>
    );
}