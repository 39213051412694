import React, { Suspense } from 'react';
import { defaultUserObj, UserLoggedInContextProvider } from "./ContextAndHOC/LoggedInContext";
import { Switch, Route, Redirect, BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import Analytics from '@aws-amplify/analytics';
import themeCalculator from './ThemeFile';
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Spinner from '@material-ui/core/CircularProgress';
import PublicPage from './views/Public/PublicPage';
import countDownTimer from './components/timeout';
import refreshTokens from "./ContextAndHOC/RefreshTokens";
import i18n from './i18nTranslation';
import { Helmet } from "react-helmet";
import axios from 'axios';
import TimeOutBackground from './components/TimeOutBackground';
import ThemeHandler from './ThemeHandler';
import CheckUpdateToNotifyUser from './CheckUpdateToNotifyUser.jsx'
import AccountSelectionScreen from './views/Public/AccountSelectionScreen';
import SAMLFinaliser from './views/Public/SAMLFinaliser';
import ProcessSSO from './views/Public/ProcessSSO'
const FirstTimeLogin = React.lazy(() => import(/*webpackChunkName: 'CoWorker' */ './views/Public/FirstTimeLogin'));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: defaultUserObj,
      changeUser: this.changeUser,
      userTimer: countDownTimer(),
      refreshTokens: refreshTokens(),
      isThemeAvailable: false,
      setTheme : this.setTheme,
      theme:'',
      staticContentUrl: props._config.termAndPrivacyUrl,
      TermsAndPrivacy: {termAndPrivacyUrl: props._config.termAndPrivacyUrl+`/Documents/`,...props._config.TermsAndPrivacyVersion,termsAndConditionFileName:props._config.termsAndConditionFileName,privacyPolicyFileName:props._config.privacyPolicyFileName},
      enableConsent:false,
      enableQuestionaire:false,
      enableHCPSurvey:false,
      enableAEReport:false,
      isStudy: false,
      productId: false,
      productName:props._config.productName?props._config.productName:"myHOPE™"
    };
  }

  componentDidMount = async () => {
    let successStatus = 200;
    try {
      await this.state.refreshTokens();
      let getUserDetails = await axios({
        method: 'get',
        url: '/api/admin/admin/getuserDetails',
        headers: { 'x-access-token': localStorage.getItem('accessToken') }
      });
      if (getUserDetails.status === successStatus) {
        getUserDetails.data.isAuthenticated = true;
        this.changeUser(getUserDetails.data);
      }
    }
    catch (error) {
      //ignoring error related to authentication
    }
  };
setTheme = (newTheme, data)=>{
  this.setState({
    theme:newTheme, 
    isThemeAvailable: true, 
    enableConsent:data.enableConsent, 
    enableQuestionaire:data.enableQuestionaire, 
    enableHCPSurvey:data.enableHCPSurvey,
    enableAEReport:data.enableAEReport,
    isStudy: data.isStudy,
    productId: data.productId,
    productName:data.productName?data.productName:this.props._config.productName?this.props._config.productName:"myHOPE™"
  })
}
changeUser = async (userObj, configData) => {
  if (userObj) {
    if (configData !== undefined) {
      userObj.config.userGuideName = this.props._config.TermsAndPrivacyVersion.userGuideName
    }
    this.setState({ userDetails: userObj });
    await Analytics.updateEndpoint({
      userAttributes: {
        role: [userObj.role],
        uID: [userObj.userTableId]
      }
    });
  } else {
    this.logout();
    this.setState({ userDetails: defaultUserObj });
  }
};

  logout = async () => {
    this.setState({theme:'',isThemeAvailable:false})
    localStorage.removeItem("__warningPopShownForSession");
    localStorage.removeItem("getAccountId");
    localStorage.removeItem("UserAuth");
    localStorage.removeItem("UserObject");
    await Promise.all[axios({
      method: "post",
      url: '/api/admin/providerauthservice/signout',
      headers: { 'x-access-token': `${localStorage.getItem('accessToken')}` }
    }),
      Analytics.updateEndpoint({
        userAttributes: {
          role: [],
          uID: ['0']
        }
      })];
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("activeStep");
  };

  render() {
    const defaultFavIcon = "/favicons/favicon.png";
    const defaultConfigFavIcon = this.props._config.portalThemeStyle.favicon;
    let customThemeFavIcon = this.state.theme.favicon;
    let isThemeAvailable= this.state.isThemeAvailable;
    let favIcon = isThemeAvailable? customThemeFavIcon? customThemeFavIcon:defaultConfigFavIcon?defaultConfigFavIcon:defaultFavIcon:defaultConfigFavIcon?defaultConfigFavIcon:defaultFavIcon;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props._config.title}</title>
          <link rel="icon" type="image/png" href={favIcon} />
        </Helmet>
        <MuiThemeProvider theme={themeCalculator(isThemeAvailable?this.state.theme:this.props._config.portalThemeStyle)}>
          <CssBaseline />
          <UserLoggedInContextProvider value={this.state}>
            <Suspense fallback={<Spinner style={{ position: "absolute", top: "50%", left: "50%" }} />}>
            <CheckUpdateToNotifyUser />
              <Router basename={process.env.PUBLIC_URL}>
                <Switch>
                    <Route path='/provider/:selectedAccountId' component={ThemeHandler} />
                    <Route path='/coworker/:selectedAccountId' component={ThemeHandler} />
                    <Route path='/superadmin/:selectedAccountId' component={ThemeHandler} />
                    <Route path='/admin/:selectedAccountId' component={ThemeHandler} />
                    <Route path='/guest' render={(props) => <PublicPage productName={this.state.productName} isStudy={this.props._config.isStudy} sidePanelText={this.props._config.sidePanelText} {...props}/>} />
                    <Route path='/firsttimelogin/:role' render={(props) => <FirstTimeLogin role={props.match.params.role} logout={this.logout} {...props}  />} />
                    <Route path='/firstscreen/:selectedAccountId' component={ThemeHandler} />
                    <Route path='/accountselection' component={AccountSelectionScreen} />
                    <Route path='/timeout' component={TimeOutBackground} />
                    <Route path='/SAMLFinaliser' component={SAMLFinaliser} />
                    <Route path='/processSSO' component={ProcessSSO} />
                    <Redirect to='/guest' />
                </Switch>
              </Router>
            </Suspense>
          </UserLoggedInContextProvider>
        </MuiThemeProvider>
      </div>
    );
  }
}
export default App;
