import React from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core";
import { useState, useEffect } from 'react';

const styles = theme => ({
    displayNotification: {
        width: '100%', background: 'yellow', color: 'black', position: 'sticky', top: 0, zIndex: 9999, textAlign: "center", padding: "10px 0"
    }
});
let currentLoadedHTML = null;
function CheckUpdateToNotifyUser(props) {
    const {t } = props;
    let [showNotification, setShowNotification] = useState(false);
    useEffect(() => {
        const timer = window.setInterval(() => {
            if(!showNotification){
                checkUpdate();
            }
        }, 600000);
        return () => {
            window.clearInterval(timer);
        };
    }, []);

    const checkUpdate = async () => {
            let result = await axios.get('/');
            if (result.status === 200) {
                if (currentLoadedHTML === null) {
                    currentLoadedHTML = result.data;
                }
                else if (currentLoadedHTML !== result.data) {
                    setShowNotification(true)
                }
            }
    }
    return (
        showNotification ? <>
            <div className={props.classes.displayNotification}>{t('publicJs.NEW_VERSION_AVAILABLE')}
                <span style={{ cursor: 'pointer' }} onClick={() => window.location.reload()}>
                    <u>{t('publicJs.REFRESH')}</u>
                </span></div></> : ""
    )
}
export default withTranslation()(withStyles(styles, { withTheme: true })(CheckUpdateToNotifyUser));